import React, { createContext, useContext, useState, useEffect } from "react";
import { useGetUserData, useGetGameHook, useCreateSubgroup } from "../../../BirdiePool/hooks/index";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userLogoutAction } from "../../../../redux/actions/loginImport";
import useNavigateToTop from "../../../../hooks/general/useNavigateToTop";
import { getUserDataFromLocalStorage } from "../../../../redux/actions/loginImport";
import LoadingScreen from "../../../LoadingScreen/LoadingScreen";
import { useNFLScoresBySeason, useNFLTeams, useNFLCurrentWeek, useQuickPickNFL, useNFLStandings } from "../../../../hooks/nfl/index";
import { useGetPoolLeaderboard } from "../../../../hooks/pools/useGetPoolLeaderboard";
import { DateTime } from "luxon";


const QuickPickNFLContext = createContext();

export const useQuickPickNFLContext = () => {
    const context = useContext(QuickPickNFLContext);
    if (!context) {
        throw new Error("useQuickPickNFLContext must be used within a QuickPickNFLProvider");
    }
    return context;
};

const QuickPickNFLProvider = ({ children }) => {
    const myUserId = getUserDataFromLocalStorage()?.user_id;
    //console.log("myUserId: ", myUserId);

    const { mutate: fetchUserData, data: userData, isLoading: userLoading, isError: userError, error: userErrorData } = useGetUserData();
    const { mutate: fetchGame, data: gameData, isLoading: gameLoading, isError: gameError, error: gameErrorData } = useGetGameHook();
    const { mutate: fetchNFLScoresBySeason, data: nflScoresBySeasonData, isLoading: nflScoresBySeasonLoading, isError: nflScoresBySeasonError, error: nflScoresBySeasonErrorData } = useNFLScoresBySeason();
    const { mutate: fetchNFLTeams, data: nflTeams, isLoading: nflTeamsLoading, isError: nflTeamsError, error: nflTeamsErrorData } = useNFLTeams();
    const { mutate: fetchNFLCurrentWeek, data: nflCurrentWeek, isLoading: nflCurrentWeekLoading, isError: nflCurrentWeekError, error: nflCurrentWeekErrorData } = useNFLCurrentWeek();
    const { mutate: fetchQuickPickNFL, data: quickPickNFLData, isLoading: quickPickNFLLoading, isError: quickPickNFLError, error: quickPickNFLErrorData } = useQuickPickNFL();
    const { mutate: fetchNFLStandings, data: nflStandings, isLoading: nflStandingsLoading, isError: nflStandingsError, error: nflStandingsErrorData } = useNFLStandings();
    const { mutate: fetchLeaderboardData, data: leaderboardData, isLoading: leaderboardLoading, isError: leaderboardError, error: leaderboardErrorData } = useGetPoolLeaderboard();
    const { mutate: createSubgroup, data: subgroupData, isLoading: subgroupLoading, isError: subgroupError, error: subgroupErrorData } = useCreateSubgroup();

    const { gameId, component, pageState } = useParams();

    const gameType = "QuickPick";
    const league = "NFL";

    const dispatch = useDispatch();
    const navigateToTop = useNavigateToTop();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    // Fetch user data first
    useEffect(() => {
        if (myUserId) {
            fetchUserData(myUserId);
        }
    }, [fetchUserData, myUserId]);
    //console.log("userData: ", userData);

    // Fetch game data second
    useEffect(() => {
        if (gameType && gameId) {
            fetchGame ( {gameType, gameID: gameId });
        }
    }, [fetchGame, gameType, gameId]);
    //console.log("gameData: ", gameData);
    const season = gameData?.season;

    // Error Handling for Game Data, will LOG OUT on expired token, otherwise will navigate to 404
    useEffect(() => {
        if (gameError) {
            console.error("Error fetching game data: ", gameErrorData);
            if (gameErrorData?.response?.status === 401) {
                dispatch(userLogoutAction({
                    logoutType: 'token-expired'
                  }));
                } else {
                  navigateToTop('/404/pool-hall');
            }
        }
    }, [gameError, gameErrorData, dispatch, navigateToTop]);

    // If there is Game Data, fetch leaderboard
    useEffect(() => {
        if (gameData) {
            fetchLeaderboardData({ gameID: gameId });
        }
    }, [fetchLeaderboardData, gameId, gameData])

    //console.log("LEADERBOARD", leaderboardData);

    // Error Handling for Leaderboard Data, will LOG OUT on expired token, otherwise will navigate to 404
    // Error handling for leaderboard fetching
  useEffect(() => {
    if (leaderboardError) {
        console.error("An error occurred while fetching the leaderboard:", leaderboardErrorData);
        if (leaderboardErrorData.response?.status === 401) {
            dispatch(userLogoutAction({
                logoutType: 'token-expired'
            }));
        } else {
            navigateToTop('/404/pool-hall');
        }
    }

    if (leaderboardData) {
        //console.log("Leaderboard data received:", leaderboardData);
    }
  }, [leaderboardData, leaderboardError, leaderboardErrorData, dispatch]);

    
    
    const [hasFetchedData, setHasFetchedData] = useState(false);
    //console.log("Data Fetched????? ", hasFetchedData);

    // Use Effect to fetch NFL data
    useEffect(() => {
        //console.log("Running Use Effect");
        if (!hasFetchedData && season) {
                //console.log("Fetching NFL Data");
                fetchNFLTeams();
                fetchNFLScoresBySeason();
                fetchNFLCurrentWeek();
                fetchQuickPickNFL(season);
                fetchNFLStandings();
    
            setHasFetchedData(true);
        }
    }, [hasFetchedData, season, fetchNFLTeams, fetchNFLScoresBySeason, fetchNFLCurrentWeek, fetchQuickPickNFL, fetchNFLStandings]);
    //console.log("nflTeams: ", nflTeams, "nflScoresBySeason: ", nflScoresBySeasonData, "nflCurrentWeek: ", nflCurrentWeek, "quickPickNFLData: ", quickPickNFLData);

    // Set loading to false when all data is fetched
    useEffect(() => {
        if (gameData  && leaderboardData && nflTeams && nflScoresBySeasonData && nflCurrentWeek && quickPickNFLData) {
            setLoading(false);
        }
      }, [gameData, leaderboardData, nflTeams, nflScoresBySeasonData, nflCurrentWeek, quickPickNFLData]);

    const gameParameters = gameData?.gameParameters[0];
    //console.log("gameParameters: ", gameParameters);

    const picksPerInterval = gameParameters?.picksPerInterval;
    const usingTiebreaker = gameParameters?.usingTiebreaker;
    const tiebreakerInfo = gameParameters?.tiebreakerInfo;
    const intervalType = gameParameters?.intervalType;
    const props = gameParameters?.props;
    const propIdsToUse = gameParameters?.propIdsToUse;
    const howManyWinners = gameParameters?.howManyWinners;
    //console.log("PropIdsToUse: ", propIdsToUse);
    const tiebreakerToUse = props?.tiebreakers;
    const gamePropsToUse = props?.gameProps;
    const selectedTeamPropsToUse = props?.selectedTeamProps;
    const opponentTeamPropsToUse = props?.opponentTeamProps;
    //console.log("Props: ", props, "Game Props: ", gamePropsToUse, "Selected Team Props: ", selectedTeamPropsToUse, "Opponent Team Props: ", opponentTeamPropsToUse, "Tiebreaker to Use", tiebreakerToUse);
    const firstInterval = gameParameters?.firstInterval;
    const lastInterval = gameParameters?.lastInterval;
    const teamsToInclude = gameParameters?.teamsToInclude; // Leave this as undefined or null for no filtering
    const daysToInclude = gameParameters?.daysToInclude;  // Leave this as undefined or null for no filtering, format is ["Sunday", "Saturday"]
    const filterTime = gameParameters?.filterTime;  // Leave this as undefined or null for no filtering, format is "HH:MM" (18:00 for 6:00 PM)
    const filterDate = gameParameters?.filterDate; // Leave this as undefined or null for no filtering, format is "YYYY-MM-DD" (2024-12-25 for December 25, 2024)
    const homeOnly = gameParameters?.homeOnly;  // If true, only include games where the home team is in teamsToInclude
    const awayOnly = gameParameters?.awayOnly;  // If true, only include games where the away team is in teamsToInclude
    const showConsenus = gameParameters?.showConsenus; // If true, show the consensus pick for each game

    const joinModalText = gameData?.joinModalText;
    //console.log("Join Modal Text: ", joinModalText);

    const currentInterval = nflCurrentWeek;
    //console.log("Current Interval: ", currentInterval);

    const [pickState, setPickState] = useState([]);
    const [clickState, setClickState] = useState(null);
    const [interval, setInterval] = useState(null);
    const [signUpState, setSignUpState] = useState(false);
    const [partyState, setPartyState] = useState("");
    const [partyId, setPartyId] = useState(gameId);
    const [defaultPartyId, setDefaultPartyId] = useState(null);
    const [defaultPartyName, setDefaultPartyName] = useState(null);
    const [joinOpen, setJoinOpen] = useState(false);
    //console.log("Interval: ", interval, "Pick State", pickState);

    useEffect(() => {
        //console.log("Running Use Effect!!!!!");
        if (userData && gameId) {
          const thisGame = userData?.Games?.find(game => game.gameID === gameId);
          if (thisGame && thisGame?.pool?.picks) {
            //console.log("Running Use Effect 2!!!!!", thisGame?.pool?.picks);
            setPickState(thisGame?.pool?.picks);
          }
        }
      }, [userData, gameId]);

    useEffect(() => {
        //console.log("Running Context Interval Use Effect")
        if (currentInterval < firstInterval) {
            setInterval(firstInterval); 
        } else {
            setInterval(currentInterval);
        }
    }, [currentInterval, firstInterval]);

    const userSubgroups = userData?.subgroups || [];
    const referrals = userData?.referrals;
    const thisGamesReferrals = referrals?.find(referral => referral.gameId === gameId);
    console.log("Referrals: ", referrals, "This Game's Referral: ", thisGamesReferrals);

    const getReferralCounts = (gameReferrals) => {
        // Check if the input has the necessary structure
        if (!gameReferrals || !gameReferrals.referrals) {
            return { totalReferrals: 0, newSignUps: 0 };
        }
    
        const totalReferrals = gameReferrals?.referrals?.length;
        const newSignUps = gameReferrals?.referrals?.filter(referral => referral.newSignUp).length;
    
        return {
            totalReferrals,
            newSignUps
        };
    };

    const referralCounts = getReferralCounts(thisGamesReferrals);
    const { totalReferrals, newSignUps } = referralCounts;
    console.log("Referral Counts: ", totalReferrals, "New Sign Ups: ", newSignUps);
   

    //console.log("teamsToInclude: ", teamsToInclude, "firstInterval: ", firstInterval, "daysToInclude:", daysToInclude, "filterTime:", filterTime, "filterDate:", filterDate);

    let nflScoresBySeason = nflScoresBySeasonData;
    //console.log("nflScoresBySeason: BEFORE Filter", nflScoresBySeason);

    if (firstInterval && nflScoresBySeasonData && gameParameters) {
        nflScoresBySeason = nflScoresBySeasonData?.filter((game) => {
            // Default condition: Check if the game week is >= firstInterval
            const isWeekValid = game.Week >= Number(firstInterval) && game.Week <= Number(lastInterval); // Check if the game week is within the range

            // Check homeOnly and awayOnly conditions
            const isHomeTeamIncluded = !teamsToInclude || teamsToInclude.includes(game.GlobalHomeTeamID); // Include all if teamsToInclude is undefined
            const isAwayTeamIncluded = !teamsToInclude || teamsToInclude.includes(game.GlobalAwayTeamID); // Include all if teamsToInclude is undefined

            // Get the day of the week from the game's date
            const gameDay = new Date(game.Date).toLocaleString('en-US', { weekday: 'long' });
            const isDayValid = !daysToInclude || daysToInclude.includes(gameDay); // Ignore days filter if it doesn't exist

            // Time Filter (in ET)
            const gameTimeET = new Date(game.DateTime).toLocaleString('en-US', { timeZone: 'America/New_York', hour: '2-digit', minute: '2-digit', hour12: false });
            const isTimeUndetermined = gameTimeET === "00:00"; // Check if time is undetermined
            const isTimeValid = !isTimeUndetermined && (!filterTime || gameTimeET >= filterTime); // Only allow games after filterTime if provided

            // Date Filter (check if the game's date matches the filterDate)
            const gameDate = new Date(game.DateTime).toISOString().split('T')[0]; // Extract the date part in "YYYY-MM-DD" format
            const isDateValid = !filterDate || gameDate === filterDate; // Only include games on the specific date

            // Logic to handle homeOnly and awayOnly flags
            if (homeOnly) {
                // If homeOnly is true, include games where the home team is in teamsToInclude
                return isWeekValid && isHomeTeamIncluded && isDayValid && isTimeValid && isDateValid;
            } else if (awayOnly) {
                // If awayOnly is true, include games where the away team is in teamsToInclude
                return isWeekValid && isAwayTeamIncluded && isDayValid && isTimeValid && isDateValid;
            } else {
                // Default behavior: Include games where either home or away team is in teamsToInclude
                return isWeekValid && (isHomeTeamIncluded || isAwayTeamIncluded) && isDayValid && isTimeValid && isDateValid;
            }
        });
    }

    //console.log("nflScoresBySeason: AFTER Filter", nflScoresBySeason);

    

    let quickPickNFLGames = quickPickNFLData;

    const currentPropIds = propIdsToUse?.filter((entry) => entry.interval === interval)[0]?.propIdsToUse || [];
    //console.log("Current Prop Ids: ", currentPropIds);

    // Filter quickPickNFLGames to include only games that are also in nflScoresBySeason
    quickPickNFLGames = quickPickNFLGames?.filter((quickGame) => {
        // First, find matching game based on GlobalGameID
        const matchingGame = nflScoresBySeason?.find(
        (seasonGame) => seasonGame.GlobalGameID === quickGame.GlobalGameID
        );

        return matchingGame; // Include the game if there's a match
    });
    
      //console.log("quickPickNFLGames: AFTER Filter 2nd ", quickPickNFLGames);


    const gameAddress = gameData?.gameAddress;
    const gameName = gameData?.gameName;
    const gameStartTime = gameData?.gameStartTime;
    const formattedGameStartTime = DateTime.fromISO(gameStartTime).setZone('America/New_York');
    const lastDayToJoin = gameData?.lastDayToJoin;
    const sponsorName = gameData?.sponsorInfo?.sponsorName;
    const gameRewards = gameData?.rewards || [];
    
    

    // Find the game in the user data
    let doesUserBelong = false;
    const thisGame = userData?.Games?.find(game => game.gameID === gameId);
    if (thisGame) {
        doesUserBelong = true;
    }
    //console.log("doesUserBelong: ", doesUserBelong);
    const myUsername = userData?.user_name;
    const userPicksFromDB = thisGame?.pool?.picks || [];
    const rewards = thisGame?.rewards || [];
    const joinReward = rewards?.find(reward => reward.interval === 0);
    //console.log("Rewards: ", rewards, "Join Reward: ", joinReward);
    //console.log("User Picks from DB: ", userPicksFromDB);

    useEffect(() => {
        if (userData && gameId) {
        const thisGame = userData?.Games?.find(game => game.gameID === gameId);
        if (thisGame && thisGame?.pool?.picks) {
        setPickState(thisGame?.pool?.picks);
        }
    }
    }, [userData, gameId]);

    // Utility function to check if defaultPartyIdInDB is in userSubgroups
    const isDefaultPartyInUserSubgroups = (partyId, subgroups) => {
        return subgroups.some(subgroup => subgroup.subGroupID === partyId);
    };

    // Get the default party ID with the additional check
    let defaultPartyIdInDB = Array.isArray(thisGame?.pool?.defaultParty) && thisGame?.pool?.defaultParty?.length > 0
    ? thisGame.pool.defaultParty[0]
    : gameId;

    //console.log("Default Party Id in DB", thisGame?.pool?.defaultParty);
    if (defaultPartyIdInDB !== gameId && !isDefaultPartyInUserSubgroups(defaultPartyIdInDB, thisGame?.subGroups || [])) {
    defaultPartyIdInDB = gameId;
    }
    //console.log("Default Party Id", defaultPartyIdInDB);


    //console.log("User Subgroups in Context Party", userSubgroups);
    const firstSubgroupId = userSubgroups[0]?.subGroupID || "";
    const firstSubgroupName = userSubgroups[0]?.name || "";
    // Get the last subgroup if there are any subgroups
    const lastIndex = userSubgroups.length - 1;
    const lastSubgroupId = lastIndex >= 0 ? userSubgroups[lastIndex]?.subGroupID || "" : "";
    const lastSubgroupName = lastIndex >= 0 ? userSubgroups[lastIndex]?.name || "" : "";
    //console.log("First Subgroup ID", firstSubgroupId, "First Subgroup Name", firstSubgroupName, "Last Subgroup ID", lastSubgroupId, "Last Subgroup Name", lastSubgroupName);


    let defaultPartyNameInDB = sponsorName ? sponsorName : "Pool Party";
    //console.log("SponsorName", sponsorName);
    if (defaultPartyIdInDB !== gameId) {
    const foundSubGroup = userSubgroups.find(subgroup => subgroup.subGroupID === defaultPartyIdInDB);
    defaultPartyNameInDB = foundSubGroup ? foundSubGroup.name : sponsorName ? sponsorName : "Pool Party";
    }
    //console.log("Default Party Name", defaultPartyNameInDB);

    //console.log("My Party Default Id", defaultPartyIdInDB, "My Party Default Name", defaultPartyNameInDB);

    //Use Effect for setting the week and party state based on the component and page state
    useEffect(() => {
        if (userData && gameData && component) {
            if (component === "MY_PICKS") {
                // If the page state is not "CURRENT", set the week to the page state, otherwise Current goes to current week or first week, whichever is greater
                if (pageState !== "CURRENT") {
                    const intervalNumber = parseInt(pageState, 10);
                    
                    if (isNaN(intervalNumber) || intervalNumber > lastInterval) {
                        // If intervalNumber is NaN, set to currentInterval
                        setInterval(currentInterval);
                    } else if (intervalNumber < firstInterval) {
                        setInterval(firstInterval);
                    } else {
                        setInterval(intervalNumber);
                    }
                } else if (firstInterval > currentInterval) {
                    setInterval(firstInterval);
                } else {
                    setInterval(currentInterval);
                }
                
                setPartyId(defaultPartyId);
                setPartyState(defaultPartyName);
              } 

              if (component === "LEADERBOARD" || component === "POOL_PICKS") {
                if (pageState !== gameId) {
                  const pageStateInUserSubgroups = userSubgroups?.find(subgroup => subgroup.subGroupID === pageState);
                  if (pageStateInUserSubgroups) {
                    setPartyId(pageState);
                    setPartyState(pageStateInUserSubgroups.name);
                  } else {
                    setPartyId(gameId);
                    setPartyState("Pool Party");
                    navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded(component)}/${uriEncoded(gameId)}`);
                  }
                }
              }
        
              if (component === "HOME") {
                setPartyId(defaultPartyId);
                setPartyState(defaultPartyName);
              }
        
              if (component === "MY_PARTY") {
                if (pageState === "MY_PARTY") {
                  setPartyId(defaultPartyId);
                  setPartyState(defaultPartyName);
                  setSignUpState(true);
                } else if (pageState === "NEW") {
                  //console.log("Last Subgroup ID", lastSubgroupId, "Last Subgroup Name", lastSubgroupName);
                  setPartyId(lastSubgroupId);
                  setPartyState(lastSubgroupName);
                  navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded('MY_PARTY')}/${uriEncoded(lastSubgroupId)}`);
                } else {
                  const subgroupName = userSubgroups?.find(subgroup => subgroup.subGroupID === pageState)?.name;
                  setPartyId(pageState);
                  setPartyState(subgroupName || "Unnamed Subgroup");
                }
            }
        }
    }, [userData, gameData, component, pageState, currentInterval, defaultPartyId, firstSubgroupId, lastSubgroupId]);


    const handleCreateSubgroup = (subgroupData) => {
        createSubgroup(subgroupData);
    };
    

    
    const finalWeekGames = nflScoresBySeason?.filter(game => game.Week === lastInterval);
    const finalWeekQuickPicks = quickPickNFLGames?.filter(game => game.Week === lastInterval);
    const finalProps = finalWeekQuickPicks ? finalWeekQuickPicks[0]?.props?.tiebreakers : [];
    const finalPropsFinalized = finalProps ? finalProps[0]?.correctOption !== undefined : false;
    console.log("Final Week Games: ", finalWeekGames, "Final Week Quick Picks: ", finalWeekQuickPicks, finalProps, finalPropsFinalized);
    
    
    const isGameOver = finalWeekGames?.every(game => 
        (game.Status === "Final" || game.Status === "F/OT") && finalPropsFinalized
    );
    
    
    console.log("Final Week Games: ", finalWeekGames, "Is Game Over?", isGameOver);

    const nowInNewYork = DateTime.now().setZone('America/New_York');

    const lastDayToJoinDateTime = DateTime.fromISO(lastDayToJoin).setZone('America/New_York');

    const hasJoinDeadlinePassed = nowInNewYork > lastDayToJoinDateTime;
    //console.log("Has Join Deadline Passed? CONTEXT", hasJoinDeadlinePassed, "Now In NY", nowInNewYork, "Last Day to Join", lastDayToJoinDateTime);

    const gatherGameInfo = (gameId, teams, schedule, standings) => {
        const gameInfo = schedule?.find(game => game.GlobalGameID === gameId);
        const homeTeamId = gameInfo?.GlobalHomeTeamID;
        const awayTeamId = gameInfo?.GlobalAwayTeamID;
        const homeLogo = teams?.find(team => team.GlobalTeamID === homeTeamId)?.WikipediaLogoURL;
        const awayLogo = teams?.find(team => team.GlobalTeamID === awayTeamId)?.WikipediaLogoURL;
        const homeName = teams?.find(team => team.GlobalTeamID === homeTeamId)?.Key;
        const awayName = teams?.find(team => team.GlobalTeamID === awayTeamId)?.Key;
        const homeWins = standings?.find(team => team.GlobalTeamID === homeTeamId)?.Wins || 0;
        const homeLosses = standings?.find(team => team.GlobalTeamID === homeTeamId)?.Losses || 0;
        const homeTies = standings?.find(team => team.GlobalTeamID === homeTeamId)?.Ties || 0;
        const homeRecord = homeTies > 0 ? `${homeWins}-${homeLosses}-${homeTies}` : `${homeWins}-${homeLosses}`;
        const awayWins = standings?.find(team => team.GlobalTeamID === awayTeamId)?.Wins || 0;
        const awayLosses = standings?.find(team => team.GlobalTeamID === awayTeamId)?.Losses || 0;
        const awayTies = standings?.find(team => team.GlobalTeamID === awayTeamId)?.Ties || 0;
        const awayRecord = awayTies > 0 ? `${awayWins}-${awayLosses}-${awayTies}` : `${awayWins}-${awayLosses}`;
        const homeScore = gameInfo?.HomeScore;
        const awayScore = gameInfo?.AwayScore;
        const gameTime = gameInfo?.DateTime;
        const gameDateTime = DateTime.fromISO(gameTime, { zone: 'America/New_York' });
        const status = gameInfo?.Status;
        const homeSpread = gameInfo?.PointSpread;
        const gameLine = homeSpread < 0 ? `(${homeName} ${homeSpread})` : homeSpread === 0 ? `Pick 'Em` : homeSpread > 0 ? `(${awayName} ${homeSpread * -1})` : "No Line";
        
        return {
            homeLogo,
            awayLogo,
            homeName,
            awayName,
            homeRecord,
            awayRecord,
            homeScore,
            awayScore,
            gameLine,
            gameTime,
            gameDateTime,
            status,
        };
    };

    const gatherDisplayBarInfo = (teamId, teams) => {
        const teamLogo = teams?.find(team => team.GlobalTeamID === teamId)?.WikipediaLogoURL;
        const teamNickname = teams?.find(team => team.GlobalTeamID === teamId)?.Name;
    
        return {
            teamLogo,
            teamNickname
        };
    }

    const oneWeekEvent = firstInterval === lastInterval;
    


    const QuickPickNFLContextValues = {
        pickState,
        setPickState,
        clickState,
        setClickState,
        interval,
        setInterval,
        signUpState,
        setSignUpState,
        partyState,
        setPartyState,
        partyId,
        setPartyId,
        defaultPartyId,
        setDefaultPartyId,
        defaultPartyName,
        setDefaultPartyName,
        firstSubgroupId,
        firstSubgroupName,
        joinOpen,
        setJoinOpen,
        gameAddress,
        gameData,
        myUserId,
        mainGameId: gameId,
        userSubgroups,
        doesUserBelong,
        currentInterval,
        firstInterval,
        lastInterval,
        gameType,
        isGameOver,
        hasJoinDeadlinePassed,
        gameName,
        myUsername,
        userPicksFromDB,
        nflStandings,
        nflScoresBySeason,
        nflTeams,
        quickPickNFLGames,
        gatherGameInfo,
        gatherDisplayBarInfo,
        teamsToInclude,
        gamePropsToUse,
        selectedTeamPropsToUse,
        opponentTeamPropsToUse,
        leaderboardInfo: leaderboardData,
        userData,
        handleCreateSubgroup,
        picksPerInterval,
        usingTiebreaker,
        league,
        intervalType,
        currentPropIds,
        propIdsToUse,
        rewards,
        joinReward,
        gameRewards,
        formattedGameStartTime,
        howManyWinners,
        oneWeekEvent,
        tiebreakerInfo,
        joinModalText,
    };

    // ADD LOADING SCREEN HERE
    if (userLoading || gameLoading || nflScoresBySeasonLoading || nflTeamsLoading || nflCurrentWeekLoading || nflStandingsLoading || quickPickNFLLoading || interval === null || loading || isGameOver === null || gameParameters === null ) {
        return <LoadingScreen />;
    }

    return (
        <QuickPickNFLContext.Provider value={QuickPickNFLContextValues}>
            {children}
        </QuickPickNFLContext.Provider>
    );
};

export default QuickPickNFLProvider;