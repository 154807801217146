import React from 'react';
import { DateTime } from 'luxon';
import { update } from 'lodash';

const AzureQuickPickDELETE = () => {

    // Step 1 Go to Redis and Get QuickPick Data
    // Step 2 Gather Quick Pick Results and Gather Update Redis Data
    // Step 3 If No Quick Pick Results, Don't need to go over user's games to mark picks as W/L.  Don't need to Update Reids Data
    // Step 4 If Quick Pick Results, Gather All Users from Azure, Go over user's games to mark picks as W/L
    // Step 5 Update Redis Data
    // Step 6 Go back over users DBs to gather Leaderboard Data
    // Step 7 Update Leaderboard Data in Redis

//     const usersGame = {
//         "rulesPage" : [ ],
//         "howToPlay" : [ ],
//         "gameID" : "5f85f7b7-4428-46dd-abda-de0e4f72635b",
//         "pool" : {
//             "stopPicksWarning" : [
//                 false
//             ],
//             "picks" : [
//                 {
//                     "id": "18763_CoinToss",
//                     "propChoice": "CoinToss",
//                     "option": "Heads",
//                     "gameId": 18763,
//                     "interval": 7,
//                     "result": "Pending"
//                 },
//                 {
//                     "id": "18763_PassingYards_BenRoethlisberger",
//                     "propChoice": "PassingYards",
//                     "option": "Under",
//                     "gameId": 18763,
//                     "interval": 7,
//                     "result": "Pending"
//                 },
//                 {
//                     "id": "18763_PassingYards_ZachWilson",
//                     "propChoice": "PassingYards",
//                     "option": "Over",
//                     "gameId": 18763,
//                     "interval": 7,
//                     "result": "Pending"
//                 },
//                 {
//                     "id": "18763_Touchdowns_NajeeHarris",
//                     "propChoice": "Touchdowns",
//                     "option": "Over",
//                     "gameId": 18763,
//                     "interval": 7,
//                     "result": "Pending"
//                 },
//                 {
//                     "id": "18763_TotalPoints_Steelers",
//                     "propChoice": "TotalPoints",
//                     "option": 34,
//                     "gameId": 18763,
//                     "interval": 7,
//                     "result": "Pending",
//                     "tiebreaker": true

//                 }
//             ]
//         },
//         "poolType" : "Survivor",
//         "clubMembers" : [ ],
//         "subGroups" : [
//             {
//                 "name" : "MTTest1",
//                 "commissioner" : true,
//                 "creator" : true,
//                 "subGroupID" : "25ce20cf-a49e-4088-ba19-ce80fc0d79bd"
//             }
//         ]
//     }

//     const RedisData = [
//         {
//             "GlobalGameID": 18751,
//             "Day": "2024-10-14T00:00:00",
//             "OverUnder": 37,
//             "HomeTeam": "DEN",
//             "AwayTeam": "LAC",
//             "gameFinalized": true,
//         },
//         {
//             "GlobalGameID": 18752,
//             "Day": "2024-10-14T00:00:00",
//             "OverUnder": 37,
//             "HomeTeam": "LV",
//             "AwayTeam": "PIT",
//             "gameFinalized": true,
//         },
//         {
//             "GlobalGameID": 18761,
//             "Day": "2024-10-15T00:00:00",
//             "OverUnder": 43.5,
//             "HomeTeam": "ARI",
//             "AwayTeam": "LAC",
//             "gameFinalized": true,
//         },
//       {
//             "GlobalGameID": 18763,
//             "Day": "2024-10-16T00:00:00",
//             "Week": 7,
//             "HomeTeam": "PIT",
//             "GlobalHomeTeamID": 28,
//             "AwayTeam": "NYJ",
//             "GlobalAwayTeamID": 24,
//             "gameFinalized": false,
//             "props": {
//                 "gameProps": [
//                     {
//                         "id": "18763_TotalPoints",
//                         "propChoice": "TotalPoints",
//                         "propText": "Total Points Scored in the Game",
//                         "options": ["Over", "Under"],
//                         "line": 45.5,
//                         "actualTotal": 50,
//                         "correctOption": "Over",
//                         "propFinalized": true
//                     },
//                     {
//                         "id": "18763_CoinToss",
//                         "propChoice": "CoinToss",
//                         "propText": "What will be the result of the coin toss?",
//                         "options": ["Heads", "Tails"],
//                         "correctOption": "Heads",
//                         "propFinalized": false
//                     }
//                 ],
//                 "homeProps": [
//                     {
//                         "id": "18763_TotalPoints_Steelers",
//                         "propChoice": "TotalPoints",
//                         "propText": "How Many Total Points by the Steelers",
//                         "options": ["Input"],
//                         "actualTotal": 32,
//                         "correctOption": 32,
//                         "propFinalized": false
//                     },
//                     {
//                         "id": "18763_PassingYards_BenRoethlisberger",
//                         "propChoice": "PassingYards",
//                         "player": "Ben Roethlisberger",
//                         "propText": "Total Passing Yards by Ben Roethlisberger",
//                         "options": ["Over", "Under"],
//                         "line": 250.5,
//                         "actualTotal": 300,
//                         "correctOption": "Over",
//                         "propFinalized": false
//                     },
//                     {
//                         "id": "18763_RushingYards_NajeeHarris",
//                         "propChoice": "RushingYards",
//                         "player": "Najee Harris",
//                         "propText": "Total Rushing Yards by Najee Harris",
//                         "options": ["Over", "Under"],
//                         "line": 75.5,
//                         "actualTotal": 100,
//                         "correctOption": "Over",
//                         "propFinalized": false
//                     },
//                     {
//                         "id": "18763_ReceivingYards_ChaseClaypool",
//                         "propChoice": "ReceivingYards",
//                         "player": "Chase Claypool",
//                         "propText": "Total Receiving Yards by Chase Claypool",
//                         "options": ["Over", "Under"],
//                         "line": 50.5,
//                         "actualTotal": 75,
//                         "correctOption": "Over",
//                         "propFinalized": false
//                     },
//                     {
//                         "id": "18763_ReceivingYards_DiontaeJohnson",
//                         "propChoice": "ReceivingYards",
//                         "player": "Diontae Johnson",
//                         "propText": "Total Receiving Yards by Diontae Johnson",
//                         "options": ["Over", "Under"],
//                         "line": 65.5,
//                         "actualTotal": 80,
//                         "correctOption": "Over",
//                         "propFinalized": false
//                     }
//                 ],
//                 "awayProps": [
//                     {
//                         "id": "18763_PassingYards_ZachWilson",
//                         "propChoice": "PassingYards",
//                         "player": "Zach Wilson",
//                         "propText": "Total Passing Yards by Zach Wilson",
//                         "options": ["Over", "Under"],
//                         "line": 225.5,
//                         "actualTotal": 300,
//                         "correctOption": "Over",
//                         "propFinalized": false
//                     },
//                     {
//                         "id": "18763_RushingYards_MichaelCarter",
//                         "propChoice": "RushingYards",
//                         "player": "Michael Carter",
//                         "propText": "Total Rushing Yards by Michael Carter",
//                         "options": ["Over", "Under"],
//                         "line": 85.5,
//                         "actualTotal": 75,
//                         "correctOption": "Under",
//                         "propFinalized": false
//                     },
//                     {
//                         "id": "18763_ReceivingYards_CoreyDavis",
//                         "propChoice": "ReceivingYards",
//                         "player": "Corey Davis",
//                         "propText": "Total Receiving Yards by Corey Davis",
//                         "options": ["Over", "Under"],
//                         "line": 50.5,
//                         "actualTotal": 75,
//                         "correctOption": "Over",
//                         "propFinalized": false
//                     },
//                     {
//                         "id": "18763_ReceivingYards_ElijahMoore",
//                         "propChoice": "ReceivingYards",
//                         "player": "Elijah Moore",
//                         "propText": "Total Receiving Yards by Elijah Moore",
//                         "options": ["Over", "Under"],
//                         "line": 40.5,
//                         "actualTotal": 55,
//                         "correctOption": "Over",
//                         "propFinalized": false
//                     }
//                 ]
//             }
//         },
//         {
//             "GlobalGameID": 18776,
//             "Day": "2024-10-17T00:00:00",
//             "OverUnder": null,
//             "HomeTeam": "PIT",
//             "AwayTeam": "NYG",
//             "gameFinalized": false,
//         },
//         {
//             "GlobalGameID": 18781,
//             "Day": "2024-10-18T00:00:00",
//             "OverUnder": null,
//             "HomeTeam": "LAC",
//             "AwayTeam": "NO",
//             "gameFinalized": false,
//         },
//         {
//             "GlobalGameID": 18802,
//             "Day": "2024-10-19T00:00:00",
//             "OverUnder": null,
//             "HomeTeam": "CLE",
//             "AwayTeam": "LAC",
//             "gameFinalized": false,
//         },
//         {
//             "GlobalGameID": 18811,
//             "Day": "2024-10-20T00:00:00",
//             "OverUnder": null,
//             "HomeTeam": "LAC",
//             "AwayTeam": "TEN",
//             "gameFinalized": false,
//         },
//         {
//             "GlobalGameID": 18818,
//             "Day": "2024-10-21T00:00:00",
//             "OverUnder": null,
//             "HomeTeam": "WAS",
//             "AwayTeam": "PIT",
//             "gameFinalized": false,
//         },
//         {
//             "GlobalGameID": 18824,
//             "Day": "2024-10-22T00:00:00",
//             "OverUnder": null,
//             "HomeTeam": "LAC",
//             "AwayTeam": "CIN",
//             "gameFinalized": false,
//         },
//         {
//             "GlobalGameID": 18832,
//             "Day": "2024-10-23T00:00:00",
//             "OverUnder": null,
//             "HomeTeam": "PIT",
//             "AwayTeam": "BAL",
//             "gameFinalized": false,
//         },
//     ]

//     function gatherQuickPickResults(redisData) {
//         let gamesToCheck = [];
//         // Get the current date in New York
//         const todayInNewYork = DateTime.now().setZone('America/New_York').startOf('day');
//         console.log("Today in New York: ", todayInNewYork);
    
//         for (let i = 0; i < redisData?.length; i++) {
//             const game = redisData[i];
//             const gameFinalized = game.gameFinalized;
//             const gameDate = DateTime.fromISO(game.Day, { zone: 'America/New_York' }).startOf('day');
            
//             // Check if the game is today or in the past
//             if (gameDate <= todayInNewYork && !gameFinalized) {
//                 gamesToCheck.push(game);
//             }
//         }
//         console.log("Games to Check: ", gamesToCheck);
//         let quickPickResults = [];
    
//         // Loop through each game
//         for (let i = 0; i < gamesToCheck.length; i++) {
//             let gameProps = gamesToCheck[i].props?.gameProps || [];  // Default to empty array if undefined
//             let homeProps = gamesToCheck[i].props?.homeProps || [];  // Default to empty array if undefined
//             let awayProps = gamesToCheck[i].props?.awayProps || [];  // Default to empty array if undefined
    
//             // Filter and add props that are not finalized and have a correctOption
//             const filteredGameProps = gameProps.filter(prop => !prop.propFinalized && prop.correctOption);
//             const filteredHomeProps = homeProps.filter(prop => !prop.propFinalized && prop.correctOption);
//             const filteredAwayProps = awayProps.filter(prop => !prop.propFinalized && prop.correctOption);
    
//             // Combine the filtered props into quickPickResults
//             quickPickResults.push(...filteredGameProps, ...filteredHomeProps, ...filteredAwayProps);
//         }
    
//         // Mark all quickPickResults as finalized
//         quickPickResults.forEach(result => {
//             const [gameId] = result.id.split('_'); // Assuming the ID is formatted as "<GlobalGameID>_<propType>"
//             const game = gamesToCheck.find(g => g.GlobalGameID === parseInt(gameId, 10));
            
//             if (game) {
//                 // Mark the specific prop as finalized
//                 const propsArray = [
//                     game.props.gameProps,
//                     game.props.homeProps,
//                     game.props.awayProps,
//                 ];
                
//                 propsArray.forEach(props => {
//                     const propToUpdate = props.find(prop => prop.id === result.id);
//                     if (propToUpdate) {
//                         propToUpdate.propFinalized = true;
//                     }
//                 });
//             }
//         });
    
//         // Check if all props in gameProps, homeProps, and awayProps are finalized
//         gamesToCheck?.forEach(game => {
//             const allPropsFinalized = [
//                 ...(game?.props?.gameProps || []),   // Default to empty array if undefined/null
//                 ...(game?.props?.homeProps || []),   // Default to empty array if undefined/null
//                 ...(game?.props?.awayProps || [])    // Default to empty array if undefined/null
//             ].every(prop => prop.propFinalized);
            
//             if (allPropsFinalized) {
//                 game.gameFinalized = true; // Mark the game as finalized
//             }
//         });
    
//         return { quickPickResults, redisData };
//     }
    
//     const quickPickResults = gatherQuickPickResults(RedisData);
//     const { quickPickResults: gameResults, redisData: updatedRedisData } = quickPickResults;
//     console.log("Quick Pick Results Data:", gameResults);
//     console.log("Updated Redis Data:", updatedRedisData);

//     function updateQuickPickResults(usersGame, quickPickResultsData) {
//         const userPicks = usersGame?.pool?.picks || [];
//         // Iterate through the user's picks
//         userPicks?.forEach((pick) => {
//             // Find the corresponding result from the quickPickResultsData
//             const resultData = quickPickResultsData?.find(result => result.id === pick.id);
            
//             // If we found the result data and the prop has been finalized
//             if (resultData) {
//                 // Compare the user's option with the correct option
//                 if (pick.option === resultData.correctOption) {
//                     pick.result = 'Win';
//                 } else {
//                     pick.result = 'Loss';
//                 }
                
//                 // If there is a tiebreaker value, calculate and assign the margin
//                 if (pick.tiebreaker) {
//                     const margin = Math.abs(resultData.correctOption - pick.option);
//                     pick.margin = margin;  // Add the calculated margin to the pick
//                 }
//             }
//         });
//     }
    
//     // Example usage:
//     updateQuickPickResults(usersGame, gameResults);
    
//     console.log(usersGame.pool.picks); // This will show the updated results with Win/Loss
    





//     const leaderboardFromRedis = [{"userId":"1","totalWins":5,"totalLosses":2,"username":"BWaite3","userFullName":"Eric Braithwaite","email":"ebraithwaite3@gmail.com","picks":[],"tiebreakers":[],"gameId":"123"},
//     {"userId":"2","totalWins":3,"totalLosses":4,"username":"Awaite03","userFullName":"Adam Braithwaite","email":"abraithwaite03@gmail.com","picks":[],"tiebreakers":[],"gameId":"123"},
//     {"userId":"4","totalWins":4,"totalLosses":3,"username":"Bwaite21","userFullName":"Brian Braithwaite","email":"bbraithwaite03@gmail.com","picks":[],"tiebreakers":[],"gameId":"123"},
//     {"userId":"3","totalWins":1,"totalLosses":6,"username":"LastPlace","userFullName":"John Doe","email":"jdoe@gmail.com","picks":[],"tiebreakers":[],"gameId":"abc"}]

//     const subgroupInfo = [
//         {"subgroupId": "1s3", "users": [1,4]},
//         {"subgroupId": "8r4", "users": [1,2]}
//     ]
//     const rankUsersByGameId = (usersData, gameId, subgroupInfo) => {
//         // Filter users by the provided gameId
//         const filteredUsers = usersData.filter(user => user.gameId === gameId);
        
//         // Sort users by totalWins in descending order
//         const sortedUsers = filteredUsers.sort((a, b) => b.totalWins - a.totalWins);
        
//         // Assign ranks, allowing ties
//         let rank = 1;
//         for (let i = 0; i < sortedUsers.length; i++) {
//             if (i > 0 && sortedUsers[i].totalWins < sortedUsers[i - 1].totalWins) {
//                 rank = i + 1; // Update rank if the current user has fewer wins than the previous one
//             }
//             sortedUsers[i].rank = rank;
//         }
    
//         // Create the main leaderboard object with gameId as key
//         const leaderboard = {
//             [gameId]: sortedUsers
//         };
    
//         // Generate subgroup leaderboards
//         if (subgroupInfo && subgroupInfo.length > 0) {
//             subgroupInfo.forEach(subgroup => {
//                 // Filter users for each subgroup using the users from the main leaderboard
//                 const subgroupUsers = sortedUsers.filter(user => 
//                     subgroup.users.includes(parseInt(user.userId))
//                 );
    
//                 // Assign ranks within the subgroup, allowing ties
//                 let subgroupRank = 1;
//                 for (let i = 0; i < subgroupUsers.length; i++) {
//                     if (i > 0 && subgroupUsers[i].totalWins < subgroupUsers[i - 1].totalWins) {
//                         subgroupRank = i + 1; // Update rank if the current user has fewer wins than the previous one
//                     }
//                     subgroupUsers[i].rank = subgroupRank;
//                 }
    
//                 // Add the subgroup leaderboard with the subgroupId as key
//                 leaderboard[subgroup.subgroupId] = subgroupUsers;
//             });
//         }
    
//         return leaderboard;
//     };

//     const rankedUsers = rankUsersByGameId(leaderboardFromRedis, "123", subgroupInfo);

// console.log("RankedLeaderboard", rankedUsers);


const getPropIdsFromGame = (newData, gameData) => {
    try {
        const { GlobalGameID, GlobalHomeTeamID, GlobalAwayTeamID, propData, interval } = newData;
        const { teamsToInclude, props } = gameData;
        
        const getPropsByType = (type) => 
            propData?.find(prop => prop.propType === type)?.createdProps || [];
        
        const gameProps = getPropsByType('gameProps');
        const homeProps = getPropsByType('homeProps');
        const awayProps = getPropsByType('awayProps');
        const tiebreakers = getPropsByType('tiebreakers');

        const {
            gameProps: gamePropsToUse = [],
            selectedTeamProps: selectedTeamPropsToUse = [],
            opponentTeamProps: opponentTeamPropsToUse = [],
            tiebreakers: tiebreakerPropsToUse = []
        } = props;

        let propIdsToUse = [];

        const filterAndAddProps = (propsArray, allowedChoices) => {
            for (const { id, propChoice } of propsArray) {
                if (allowedChoices.includes(propChoice)) propIdsToUse.push(id);
            }
        };

        // Game props
        filterAndAddProps(gameProps, gamePropsToUse);

        // Team-based props
        const includeBothTeams = teamsToInclude.includes(GlobalHomeTeamID) && teamsToInclude.includes(GlobalAwayTeamID);

        if (includeBothTeams || teamsToInclude.includes(GlobalHomeTeamID)) {
            filterAndAddProps(homeProps, selectedTeamPropsToUse);
            filterAndAddProps(awayProps, includeBothTeams ? selectedTeamPropsToUse : opponentTeamPropsToUse);
        } else if (teamsToInclude.includes(GlobalAwayTeamID)) {
            filterAndAddProps(awayProps, selectedTeamPropsToUse);
            filterAndAddProps(homeProps, opponentTeamPropsToUse);
        }

        // Tiebreaker props
        filterAndAddProps(tiebreakers, tiebreakerPropsToUse);

        return { interval, propIdsToUse };

    } catch (error) {
        console.log('Error:', error);
        return {
            status: 500,
            body: 'An error occurred'
        };
    }
};

const newData = {
    league: 'NFL',
    season: '2024',
    interval: 8,
    GlobalHomeTeamID: 28,
    GlobalAwayTeamID: 2,
    GlobalGameID: 18818, // Assuming globalGameID is defined as a single number
    propData: [
        {
            propType: "tiebreakers",
            createdProps: [ {
                "id": "11y85369-b652-4f95-a75e-8e9fdf738be4",
                "propChoice": "TotalPoints",
"tableHeader": "Total Points",
                "propText": "How many total points will be scored in the game?",
                "options": ["Input"],
                "tiebreaker": true,
                "correctOption": 55,
                "propFinalized": false
            }]
        },
        {
            propType: "gameProps", 
            createdProps: [
                {
                    "id": "0985aa47-928c-4772-be25-30fab3badedd",
                    "propChoice": "CoinToss",
        "tableHeader": "Coin Toss",
                    "propText": "What will be the result of the coin toss?",
                    "options": ["Heads", "Tails"],
                    "correctOption": "Heads",
                    "propFinalized": true,
        "points":1
                }
            ]
        },
        {
            propType: "homeProps",
            createdProps: [{
                "id": "4g2c0af1-843f-4efb-b83d-1f78160395a0",
                "propChoice": "PassingYards"
,
"tableHeader": "Wilson Pass Yards",
                "player": "Russell Wilson",
                "propText": "Total Passing Yards by Russell Wilson",
                "options": ["Over", "Under"],
                "line": 250.5,
                "actualTotal": 300,
                "correctOption": "Over",
                "propFinalized": false,
"points":1
            }]
        },
        {
            propType: "awayProps",
            createdProps: [{
                "id": "03n7fa1b-9bfc-49bf-acf4-abf06565cffe",
                "propChoice": "PassingYards",
"tableHeader": "Simms Pass Yards",
                "player": "Phil Simms",
                "propText": "How many passing yards by Phil Simms?",
                "options": ["Less than 100", "101-175", "176-250", "More than 250"],
                "actualTotal": 134,
                "correctOption": "101-175",
                "propFinalized": false,
"points":2
            },
            {
                "id": "21n7fa1b-9bfc-49bf-acf4-abf06565cffe",
                "propChoice": "RushingYards",
"tableHeader": "Simms Rush Yards",
                "player": "Phil Simms",
                "propText": "How many rushing yards by Phil Simms?",
                "options": ["Less than 100", "101-175", "176-250", "More than 250"],
                "actualTotal": 134,
                "correctOption": "101-175",
                "propFinalized": false,
"points":2
            }]
        }
    ]
};

// Simplified function to populate gameResults from propData
function populateGameResults(propData) {
    if (!Array.isArray(propData)) {
        return [];
    }

    const gameResults = [];
    const propertiesToAdd = ['tiebreakers', 'gameProps', 'homeProps', 'awayProps'];

    propData.forEach(item => {
        if (propertiesToAdd.includes(item.propType) && Array.isArray(item.createdProps)) {
            gameResults.push(...item.createdProps);
        }
    });

    return gameResults;
}


const gameResults = populateGameResults(newData.propData);
console.log("Game Results:", gameResults);

const usersGame = {
    "_id" : "671125d45af62f5f88954c00",
    "gameID" : "359e3e6e-9279-459a-b5d7-c6997c9953ae",
    "pool" : {
        "stopPicksWarning" : false,
        "picks" : [
            {
                "id" : "0985aa47-928c-4772-be25-30fab3badedd",
                "propChoice" : "CoinToss",
                "option" : "Heads",
                "gameId" : 18776,
                "interval" : 8,
                "result" : "Pending"
            },
            {
                "id" : "4g2c0af1-843f-4efb-b83d-1f78160395a0",
                "propChoice" : "PassingYards",
                "option" : "Under",
                "gameId" : 18776,
                "interval" : 8,
                "result" : "Pending"
            },
            {
                "id" : "03n7fa1b-9bfc-49bf-acf4-abf06565cffe",
                "propChoice" : "PassingYards",
                "option" : "Less than 100",
                "gameId" : 18776,
                "interval" : 8,
                "result" : "Pending"
            },
            {
                "id" : "4285aa47-928c-4772-be25-30fab3badedd",
                "propChoice" : "CoinToss",
                "option" : "Heads",
                "gameId" : 18763,
                "interval" : 7,
                "result" : "Win",
                "points" : 1
            },
            {
                "id" : "9b5c0af1-843f-4efb-b83d-1f78160395a0",
                "propChoice" : "PassingYards",
                "option" : "Over",
                "gameId" : 18763,
                "interval" : 7,
                "result" : "Win",
                "points" : 1
            },
            {
                "id" : "75b7fa1b-9bfc-49bf-acf4-abf06565cffe",
                "propChoice" : "PassingYards",
                "option" : "More than 250",
                "gameId" : 18763,
                "interval" : 7,
                "result" : "Loss",
                "points" : 0
            },
            {
                "id" : "49f85369-b652-4f95-a75e-8e9fdf738be4",
                "propChoice" : "TotalPoints",
                "option" : 53,
                "gameId" : 18763,
                "interval" : 7,
                "result" : "Pending",
                "tiebreaker" : true,
                "margin" : 1
            },
            {
                "id" : "7u85aa47-928c-4772-be25-30fab3badedd",
                "propChoice" : "CoinToss",
                "option" : "Tails",
                "gameId" : 18752,
                "interval" : 6,
                "result" : "Loss",
                "points" : 0
            },
            {
                "id" : "z5b7fa1b-9bfc-49bf-acf4-abf06565cffe",
                "propChoice" : "PassingYards",
                "option" : "Under",
                "gameId" : 18752,
                "interval" : 6,
                "result" : "Loss",
                "points" : 0
            },
            {
                "id" : "0l5c0af1-843f-4efb-b83d-1f78160395a0",
                "propChoice" : "PassingYards",
                "option" : "Over",
                "gameId" : 18752,
                "interval" : 6,
                "result" : "Win",
                "points" : 1
            },
            {
                "id" : "86y85369-b652-4k85-a75e-8e9fdf738be4",
                "propChoice" : "TotalPoints",
                "option" : 30,
                "gameId" : 18752,
                "interval" : 6,
                "tiebreaker" : true,
                "margin" : 15,
                "result" : "Pending"
            },
            {
                "id" : "11y85369-b652-4f95-a75e-8e9fdf738be4",
                "propChoice" : "TotalPoints",
                "option" : 35,
                "gameId" : 18776,
                "interval" : 8,
                "tiebreaker" : true,
                "result" : "Pending"
            }
        ]
    },
    "poolType" : "QuickPick",
    "clubMembers" : [ ],
    "subGroups" : [ ],
    "howToPlay" : [ ],
    "rulesPage" : [ ]
};




const updateQuickPickUserResults = (usersGame, quickPickResultsData, database, gameId) => {
    try {
        let changes = false;

        const updatedPicks = usersGame?.pool?.picks?.map(pick => {
            // Find the corresponding result from the quickPickResultsData
            const resultData = quickPickResultsData?.find(result => result.id === pick.id);

            // If result data is found and the result is pending
            if (resultData && pick?.result === 'Pending') {
                let updatedResult = 'Pending';
                let points = null;
                let margin = null;

                // Compare the user's option with the correct option
                if (pick.tiebreaker) {
                    // If the pick is a tiebreaker, determine the margin and mark as Done
                    margin = Math.abs(Number(resultData.correctOption) - Number(pick.option));
                    updatedResult = 'Done';
                    
                    return { ...pick, result: updatedResult, margin };
                } else if (pick.option === resultData.correctOption) {
                    updatedResult = 'Win'; 
                    points = resultData.points;
                    } else {
                        updatedResult = 'Loss';
                        points = 0;
                    }

                // If a change was detected, set the flag and return updated pick
                if (updatedResult !== 'Pending') {
                    changes = true;
                    return { ...pick, result: updatedResult, points };
                }
            }

            // Return the original pick if no changes
            return pick;
        });

        // If any changes occurred, update the user data in the database
        if (changes === true) {
            console.log('Changes detected, updating user data.');
            // await setUserData(updatedPicks, database, gameId, usersGame.user_id);
        } else {
            console.log('No changes detected.');
        }

        return updatedPicks;
    } catch (error) {
        throw new Error(error);
    }
};    


    

const gameDataTest = {
    "teamsToInclude" : [
        28
    ],
    "props" : {
        "tiebreakers" : [
            "TotalPoints"
        ],
        "gameProps" : [
            "CoinToss"
        ],
        "selectedTeamProps" : [
            "PassingYards"
        ],
        "opponentTeamProps" : [
            "PassingYards"
        ]
    }
}

const test = getPropIdsFromGame(newData, gameDataTest);
console.log("Interval to Use:", test);

const quickPickResultsTest = updateQuickPickUserResults(usersGame, gameResults);
console.log("Quick Pick Results Test:", quickPickResultsTest);


const userData = [
    {
        "user_id" : "b723f91e-a68b-4404-a605-0664f1e2e3da",
        "first_name" : "Eric",
        "last_name" : "Braithwaite",
        "user_name" : "BWaite3",
        "phone" : "+1 412 580 3277",
        "email" : "ebraithwaite3@gmail.com",
        "Games" : [
            {
                "_id" : "671125d45af62f5f88954c00",
                "gameID" : "359e3e6e-9279-459a-b5d7-c6997c9953ae",
                "pool" : {
                    "stopPicksWarning" : false,
                    "picks" : [
                        {
                            "id" : "0985aa47-928c-4772-be25-30fab3badedd",
                            "propChoice" : "CoinToss",
                            "option" : "Heads",
                            "gameId" : 18776,
                            "interval" : 8,
                            "result" : "Pending"
                        },
                        {
                            "id" : "4g2c0af1-843f-4efb-b83d-1f78160395a0",
                            "propChoice" : "PassingYards",
                            "option" : "Under",
                            "gameId" : 18776,
                            "interval" : 8,
                            "result" : "Pending"
                        },
                        {
                            "id" : "03n7fa1b-9bfc-49bf-acf4-abf06565cffe",
                            "propChoice" : "PassingYards",
                            "option" : "Less than 100",
                            "gameId" : 18776,
                            "interval" : 8,
                            "result" : "Pending"
                        },
                        {
                            "id" : "4285aa47-928c-4772-be25-30fab3badedd",
                            "propChoice" : "CoinToss",
                            "option" : "Heads",
                            "gameId" : 18763,
                            "interval" : 7,
                            "result" : "Win",
                            "points" : 1
                        },
                        {
                            "id" : "9b5c0af1-843f-4efb-b83d-1f78160395a0",
                            "propChoice" : "PassingYards",
                            "option" : "Over",
                            "gameId" : 18763,
                            "interval" : 7,
                            "result" : "Win",
                            "points" : 1
                        },
                        {
                            "id" : "75b7fa1b-9bfc-49bf-acf4-abf06565cffe",
                            "propChoice" : "PassingYards",
                            "option" : "More than 250",
                            "gameId" : 18763,
                            "interval" : 7,
                            "result" : "Loss",
                            "points" : 0
                        },
                        {
                            "id" : "49f85369-b652-4f95-a75e-8e9fdf738be4",
                            "propChoice" : "TotalPoints",
                            "option" : 53,
                            "gameId" : 18763,
                            "interval" : 7,
                            "result" : "Pending",
                            "tiebreaker" : true,
                            "margin" : 1
                        },
                        {
                            "id" : "7u85aa47-928c-4772-be25-30fab3badedd",
                            "propChoice" : "CoinToss",
                            "option" : "Tails",
                            "gameId" : 18752,
                            "interval" : 6,
                            "result" : "Loss",
                            "points" : 0
                        },
                        {
                            "id" : "z5b7fa1b-9bfc-49bf-acf4-abf06565cffe",
                            "propChoice" : "PassingYards",
                            "option" : "Under",
                            "gameId" : 18752,
                            "interval" : 6,
                            "result" : "Loss",
                            "points" : 0
                        },
                        {
                            "id" : "0l5c0af1-843f-4efb-b83d-1f78160395a0",
                            "propChoice" : "PassingYards",
                            "option" : "Over",
                            "gameId" : 18752,
                            "interval" : 6,
                            "result" : "Win",
                            "points" : 1
                        },
                        {
                            "id" : "86y85369-b652-4k85-a75e-8e9fdf738be4",
                            "propChoice" : "TotalPoints",
                            "option" : 30,
                            "gameId" : 18752,
                            "interval" : 6,
                            "tiebreaker" : true,
                            "margin" : 15,
                            "result" : "Pending"
                        },
                        {
                            "id" : "11y85369-b652-4f95-a75e-8e9fdf738be4",
                            "propChoice" : "TotalPoints",
                            "option" : 35,
                            "gameId" : 18776,
                            "interval" : 8,
                            "tiebreaker" : true,
                            "result" : "Pending"
                        }
                    ]
                },
                "poolType" : "QuickPick",
                "clubMembers" : [ ],
                "subGroups" : [ ],
                "howToPlay" : [ ],
                "rulesPage" : [ ]
            }
        ],
    }
];

const dataForLeaderBoard =  (gameId, userData) => {

    let leaderBoard = [];

    try{
        for(let i = 0; i < userData.length; i++){
            const username = userData[i]?.user_name;
            const userFullName = `${userData[i]?.first_name} ${userData[i]?.last_name}`;
            const email = userData[i]?.email;
            const userId = userData[i]?.user_id;
            const games = userData[i]?.Games || [];
            console.log("Games", games);
            const pool = games[0]?.pool || {};
            console.log("Pool", pool);
            const picks = pool?.picks || [];
            console.log("Picks", picks);
            let totalPoints = 0;
            let totalWins = 0;
            let totalLosses = 0;
            let totalMargin = 0;
            let tiebreakers = [];

            picks.forEach(pick => {
                if (pick.result === "Win") {
                    totalWins++;
                } else if (pick.result === "Loss") {
                    totalLosses++;
                }
                
                if (pick.points) {
                    totalPoints += pick.points;
                }

                if (pick?.tiebreaker) {
                    const interval = pick.interval;
                    const option = pick.option;
                    const margin = pick.margin;
                    const tiebreakerInfo = {
                        interval,
                        option,
                        margin
                    }
                    // Push tiebreakerInfo into tiebreakers array
                    tiebreakers.push(tiebreakerInfo);
                }
            });

            const finalData =  {
                userId,
                totalPoints,
                totalWins,
                totalLosses,
                username,
                userFullName,
                email,
                picks,
                tiebreakers,
                gameId
            }

            leaderBoard.push(finalData);

        }

        return leaderBoard;

    } catch (err) {
        throw new Error(err);
    }
}

const leaderBoardData = dataForLeaderBoard("123", userData);
console.log("LeaderBoardData", leaderBoardData);

    

    return (
        <div>
            <h1>AzureQuickPickDELETE</h1>
        </div>
    )
}

export default AzureQuickPickDELETE;