import React from "react";
import { Box, Typography, Button } from "@mui/material";
import useNavigateToTop from "../../hooks/general/useNavigateToTop";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const CoachsQuickPickForHomePage = () => {

    const navigateToTop = useNavigateToTop();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    return (
        <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '32px',
        }}
      >
      <Box>
      <Box
        className="our-pools-container"
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0 17px',
          boxSizing: 'border-box',
          gap: '16px',
          lineHeight: 'normal',
          letterSpacing: 'normal',
          textAlign: 'left',
          fontSize: '18.3px',
          color: '#000',
          fontFamily: 'Inter',
          maxWidth: '435px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          <Box
            sx={{
              height: '43px',
              position: 'relative',
              letterSpacing: '-0.02em',
              lineHeight: '150%',
              display: 'inline-block',
              marginBottom: isMobile ? '0' : '24px',
            }}
          >
            <img
                loading="lazy"
                alt="Coach's"
                src="https://poolpartyfilestorage.blob.core.windows.net/media/CoachsQuickPickLogo.png"
                style={{
                    width: isMobile ? '175px' : '250px', // Control size for mobile and desktop
                    height: 'auto', // Maintain aspect ratio
                    marginRight: '16px', // Space between image and text
                    verticalAlign: 'middle', // Align image with text
                    margin: '0 auto'
                }}
            />
            {/* <Typography
              component="p"
              sx={{
                margin: 0,
                fontSize: isMobile ? '18px' : '40px',
                fontWeight: 800, // Applying font-weight directly
                color: '#CC293C',
                whiteSpace: 'nowrap'
              }}
            >
              <span>
                NFL 2nd Shot Survivor
              </span>
            </Typography> */}
            {/* <Typography
              component="p"
              sx={{
                margin: 0,
                fontSize: isMobile ? '12px' : '20px',
                textAlign: isMobile ? 'left' : 'center', 
                fontWeight: 800, 
                color: '#CC293C'
              }}
            >
              <i>Entry Deadline: Sun Oct 13 @ 1:00pm</i>
            </Typography> */}
          </Box>
        </Box>
       {isMobile && (
        <img
          loading="lazy"
          alt="Pittsburgh Quick Pick Badge"
          src="https://poolpartyfilestorage.blob.core.windows.net/media/poolPartyPittsbughQuickPick.png"
          style={{
            height: '90px',
            width: '72px',
            position: 'relative',
            objectFit: 'cover',
          }}
        />
        )}
      </Box>
      <Box
        sx={{
          maxWidth: isMobile ? '295px' : '420px',
          margin: '0 auto',
          marginTop: isMobile ? '32px' : '80px',
        }}
      >
        <Typography
          sx={{
            fontSize: isMobile ? '14px' : '18px',
            fontWeight: 700,
            marginTop: '24px',
          }}
        >
              Compete to win exclusive Coach’s rewards and experience Steelers games like never before!
          </Typography>
          <Typography
            sx={{
              fontSize: isMobile ? '14px' : '16px',
              fontWeight: 500,
              textAlign: 'left', // Aligns the entire text to the left
              marginTop: '10px',
            }}
          >
            <span style={{ fontWeight: 700 }}>Steelers-Themed Props</span> -  Your game within the game. Add a new element of excitement and engagement to Steelers games.
          </Typography>
          <Typography
            sx={{
              fontSize: isMobile ? '14px' : '16px',
              fontWeight: 500,
              textAlign: 'left', // Aligns the entire text to the left
            }}
          >
            <span style={{ fontWeight: 700 }}>Coach's Prizes</span> - What’s better than football, fun, food, and drinks? Football, fun and FREE food and drinks.
          </Typography>
          <Button
            variant="contained"
            onClick={() =>
              navigateToTop(`/quick-pick-nfl/${uriEncoded('58a6c362-b8f6-4692-84cf-db0f9ad2e264')}/HOME/HOME`)
            }            sx={{
              marginTop: '24px',
              width: '120px',
              whiteSpace: 'nowrap',
            }}
          >
            POOL HOME
          </Button>
      </Box>
      </Box>
      {!isMobile && (
        <Box>
        <img
          loading="lazy"
          alt="Pittsburgh Quick Pick Badge"
          src="https://poolpartyfilestorage.blob.core.windows.net/media/poolPartyPittsbughQuickPick.png" 
          style={{
            height: '240px',
            width: '300px',
            position: 'relative',
            objectFit: 'contain',
            marginLeft: '117px',
          }}
        />
        </Box>
      )}
      </Box>
  );
};

export default CoachsQuickPickForHomePage;