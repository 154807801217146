import React, { useState } from "react";
import { Typography, Button, Box, Modal } from "@mui/material";
import './GameHomePage.scss';
import CreatePartyInfo from "../MyParty/CreatePartyInfo";
import useNavigateToTop from "../../hooks/general/useNavigateToTop";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DateTime } from "luxon";
import { CardMedia } from "@mui/material";
import ChampionDisplay from "../ChampionDisplay/ChampionDisplay";
import Top3Display from "../ChampionDisplay/Top3Display";
import HomePageSponsor from "./GameHomePageComponents/HomePageSponsor";
import HomePageTagLine from "./GameHomePageComponents/HomePageTagLine";
import HomePagePrizes from "./GameHomePageComponents/HomePageEntryAndPrizes";
import HomePageHowTo from "./GameHomePageComponents/HomePageHowTo";
import HomePageScreenshots from "./GameHomePageComponents/HomePageScreenshots";
import HomePageCopyLink from "./GameHomePageComponents/HomePageCopyLink";
// NEED TO FIGURE OUT HOW TO Make the Pool Rankings Table DYNAMIC!!!!!!!!!!!!!!!!!


const GameHomePage = ({ 
    homePageProps: { 
        setSignUpState, 
        gameAddress, 
        userData, 
        gameData, 
        setInterval, 
        currentInterval, 
        userSubgroups, 
        doesUserBelong,
        myUserId,
        partyState,
        setPartyState,
        partyId,
        setPartyId,
        setDefaultPartyId,
        setDefaultPartyName,
        gameType,
        mainGameId,
        joinOpen,
        setJoinOpen,
        whosInFirst,
        whosInFirstCharacterCount,
        whosInSecond,
        whosInSecondCharacterCount,
        whosInThird,
        whosInThirdCharacterCount,
        isGameOver,
        hasJoinDeadlinePassed,
        howManyWinners,
    } 
}) => {
    //console.log("IS GAME OVER", isGameOver)
    //console.log("UserData", userData, "Has Join Deadline Passed HOMEPAGE", hasJoinDeadlinePassed, "Join Open", joinOpen)
    const gameName = gameData?.gameName;
    const homePageInfo = gameData?.homePage;
    const sponsorInfo = gameData?.sponsorInfo;
    const sponsorName = sponsorInfo?.sponsorName;
    const sponsorLogo = sponsorInfo?.sponsorLogo;
    const sponsorWebsite = sponsorInfo?.sponsorWebsite;
    const lastDayToJoin = gameData?.lastDayToJoin;

    //Parse the date string in ET (Eastern Time) zone
    const date = DateTime.fromISO(lastDayToJoin, { zone: "America/New_York" });

    //Format the date to the desired output
    const formattedDate = date?.toFormat('cccc LLL d @ h a') + ' ET';

    const prizes = gameData?.prizes;
    const howToPlayInfo = gameData?.howToPlay;
    const poolPreviewVideo = gameData?.homePage?.poolPreviewVideo;
    const screenshots = gameData?.homePage?.screenshots;
    //console.log("screenshots: ", screenshots);
    const logo = gameData?.clubLogo;

    const navigateToTop = useNavigateToTop();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [showModal, setShowModal] = useState(false);
    const [modalImage, setModalImage] = useState(null);

    const handleCloseModal = () => {
        setShowModal(false);
        setModalImage(null);
    }

    const handleOpenModal = (image) => {
        setShowModal(true);
        setModalImage(image);
    }

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    const handleRulesClick = () => {
        navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/RULES/RULES`);
    }

   const handleJoinClick = () => {
        if (!userData) {
            navigateToTop(`/login/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/HOME/HOME`);
        } else {
            //console.log("Setting Join Open True")
            setJoinOpen(true);
        }
    }

    return (
        <div>
            {!doesUserBelong && (
                <Typography className="game-home-page-join-deadline">
                    Entry Deadline: {formattedDate}
                </Typography>
            )}

            {!hasJoinDeadlinePassed && 
                <HomePageCopyLink 
                    mainGameId={mainGameId}
                    gameAddress={gameAddress}
                />
            }

            <Box className="game-home-page-logo-box">
                <img 
                    src={logo} 
                    alt="Logo" 
                    className="game-home-page-logo" 
                />
            </Box>


            {sponsorName && (
                <HomePageSponsor
                    sponsorName={sponsorName}
                    sponsorLogo={sponsorLogo}
                    sponsorWebsite={sponsorWebsite}
                    gameType={gameType}
                />
            )}
                
            {/* Info about winner once the game has eneded ADD BACK!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/}
            {/* {isGameOver && (
                <ChampionDisplay
                    whosInFirst={whosInFirst}
                    whosInFirstCharacterCount={whosInFirstCharacterCount}
                    partyName={"Pool Party"}
                    line2={"2024 NFL Survivor Champion"}
                    line4={"2025 NFL Survivor Competition."}
                />                 
            )} */}

            {isGameOver && howManyWinners === 3 && (
                <Top3Display
                    whosInFirst={whosInFirst}
                    whosInFirstCharacterCount={whosInFirstCharacterCount}
                    partyName={partyState}
                    line2={`${gameName} Top 3`}
                    whosInSecond={whosInSecond}
                    whosInSecondCharacterCount={whosInSecondCharacterCount}
                    whosInThird={whosInThird}
                    whosInThirdCharacterCount={whosInThirdCharacterCount}
                    sponsorName={sponsorName}
                />
            )}

            {!doesUserBelong && (
                <HomePagePrizes
                    prizes={prizes}
                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                    doesUserBelong={doesUserBelong}
                />
            )}

            <HomePageTagLine 
                homePageInfo={homePageInfo}
            />

                {doesUserBelong && (
                    <>
                        <Box className="game-home-page-button-group">
                            <Button 
                                variant="contained" 
                                className="game-home-page-button my-picks-button"
                                onClick={() => {
                                    setInterval(currentInterval);
                                    navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded("MY_PICKS")}/${uriEncoded(currentInterval)}`);
                                }}
                            >
                                MY PICKS
                            </Button>
                            <Button 
                                variant="contained" 
                                className="game-home-page-button pool-rules-button"
                                onClick={() => { navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded("RULES")}/${uriEncoded("RULES")}`); }}
                            >
                                POOL RULES
                            </Button>
                        </Box>

                        {userSubgroups?.length > 0 && (
                            <>
                                {/* <Box
                                    sx={{
                                        marginBottom: '16px', 
                                    }}
                                >
                                    <SurvivorPoolRankingsTable 
                                        myUserId={myUserId} 
                                        gameId={mainGameId} 
                                        userSubgroups={userSubgroups} 
                                        partyState={partyState}
                                        setPartyState={setPartyState}
                                        partyId={partyId}
                                        setPartyId={setPartyId}
                                        setDefaultPartyId={setDefaultPartyId} 
                                        setDefaultPartyName={setDefaultPartyName}
                                    />
                                </Box> */}
                                {!isGameOver && (
                                    <CreatePartyInfo 
                                        setSignUpState={setSignUpState} 
                                        gameAddress={gameAddress}
                                        doesUserBelong={doesUserBelong}
                                        gameName={gameName}
                                        setJoinOpen={setJoinOpen}
                                    />
                                )}
                            </>
                        )}
                    </>
                )}

            {(doesUserBelong && userSubgroups?.length === 0 && !isGameOver && !(gameType === 'QuickPick' && sponsorName)) && (
                <>
                    <Typography className="game-home-page-title">
                        My Party
                    </Typography>
                    <Typography className="game-home-page-subtitle">
                        Create a competition with your private group.
                    </Typography>

                    
                    <CreatePartyInfo 
                        setSignUpState={setSignUpState} 
                        gameAddress={gameAddress}
                        doesUserBelong={doesUserBelong}
                        gameName={gameName}
                        setJoinOpen={setJoinOpen}
                    />
                </>
            )}


            {!doesUserBelong && (
                <Box className="game-home-page-action-buttons">
                    {!hasJoinDeadlinePassed && (
                        <Button 
                            variant="contained" 
                            className="game-home-page-button join-pool-button"
                            onClick={() => handleJoinClick()}
                        >
                            JOIN POOL
                        </Button>
                    )}
                    <Button 
                        variant="contained" 
                        className="game-home-page-button rules-button"
                        onClick={() => { navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded("RULES")}/${uriEncoded("RULES")}`); }}
                    >
                        RULES
                    </Button>
                </Box>
            
            )}

            {doesUserBelong && (
                <HomePagePrizes
                    prizes={prizes}
                    hasJoinDeadlinePassed={hasJoinDeadlinePassed}
                />
            )}
            
            <Box 
                className="game-home-page-bottom-half-container"
                sx={{
                    gridTemplateColumns: poolPreviewVideo ? (isMobile ? '1fr' : '1fr 1fr') : '1fr',
                }}
            >

                <HomePageHowTo
                    handleRulesClick={handleRulesClick}
                    howToPlayInfo={howToPlayInfo}
                />
                {poolPreviewVideo && (
                <Box className="game-home-page-media-container">
                    <Typography 
                        variant="body1" 
                        className="game-home-page-pool-preview-text" // Added className here
                    >
                        Pool Preview
                    </Typography>
                    <Typography
                        variant="body1"
                        className="game-home-page-pool-preview-text-2" // Added className here
                    >
                        Learn how to start playing by watching this easy to follow video tutorial..
                    </Typography>
                    
            

                    {poolPreviewVideo && (
                    <Box className="game-home-page-video-container">
                        <CardMedia
                            component="video"
                            src={poolPreviewVideo}
                            allow="autoPlay"
                            controls
                            className="game-home-page-video"
                        />
                    </Box>
                    )}
                    {screenshots && screenshots.length > 0 && (
                        <HomePageScreenshots images={screenshots} handleOpenModal={handleOpenModal} />
                    )}

                </Box>
                )}
            </Box>

            {/* {joinOpen && (
                <JoinGameModal
                    joinOpen={joinOpen}
                    setJoinOpen={setJoinOpen}
                    gameName={gameName}
                    gameAddress={gameAddress}
                    gameId={mainGameId}
                    gameType={gameType}
                    logo={logo}
                    myUserId={myUserId}
                    setSignUpState={setSignUpState}
                    sponsorSecondaryLogo={sponsorSecondaryLogo}
                    sponsorName={sponsorName}
                />
            )} */}
            {showModal && (
                <Modal
                    open={showModal}
                    onClose={handleCloseModal}
                    sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '0 1rem',
                    }}
                >
                    <Box
                    sx={{
                        width: isMobile ? '300px' : '600px',
                        height: isMobile ? '500px' : '750px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'white',
                        borderRadius: '4px',
                        overflow: 'hidden',
                        boxSizing: 'border-box', // Ensure padding is included in the dimensions
                    }}
                    >
                    <Box
                        sx={{
                        width: '100%',
                        height: '95%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        overflowY: 'auto',
                        }}
                    >
                        <img
                        src={modalImage}
                        alt="Image placeholder"
                        style={{
                            objectFit: 'contain',
                            maxWidth: '100%',
                            maxHeight: 'calc(100% - 50px)', // Ensure button space
                        }}
                        />
                        <Button 
                            variant="contained"
                            onClick={handleCloseModal}
                            sx={{
                                marginTop: '10px',
                            }}
                        >
                            CLOSE
                        </Button>
                    </Box>
                    </Box>
                </Modal>
            )}
        </div>
    )
}

export default GameHomePage;
