import React from "react";
import { Box, Typography, Button } from "@mui/material";
import useNavigateToTop from "../../hooks/general/useNavigateToTop";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const MikeAndTonysForHomePage = () => {

    const navigateToTop = useNavigateToTop();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const isLargeScreen = useMediaQuery("(min-width:900px)"); 

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
      }

  return (
    <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '32px',
        }}
      >
      <Box>
      <Box
        className="our-pools-container"
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0 17px',
          boxSizing: 'border-box',
          gap: '16px',
          lineHeight: 'normal',
          letterSpacing: 'normal',
          textAlign: 'left',
          fontSize: '18.3px',
          color: '#000',
          fontFamily: 'Inter',
          maxWidth: '435px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          <Box
            sx={{
              height: '43px',
              position: 'relative',
              letterSpacing: '-0.02em',
              lineHeight: '150%',
              display: 'inline-block',
              marginBottom: isMobile ? '0' : '24px',
            }}
          >
            <img
                loading="lazy"
                alt="Mike & Tony's"
                src="https://poolpartyfilestorage.blob.core.windows.net/media/mikeAndTonysHorizontalLogo.webp"
                style={{
                    width: isMobile ? '175px' : '250px', // Control size for mobile and desktop
                    height: 'auto', // Maintain aspect ratio
                    marginRight: '16px', // Space between image and text
                    verticalAlign: 'middle', // Align image with text
                    margin: '0 auto'
                }}
            />
            <Typography
              component="p"
              sx={{
                margin: 0,
                fontSize: isMobile ? '18px' : '40px',
                fontWeight: 800, // Applying font-weight directly
                color: '#CC293C',
                whiteSpace: 'nowrap'
              }}
            >
              <span>
                NFL 2nd Shot Survivor
              </span>
            </Typography>
            <Typography
              component="p"
              sx={{
                margin: 0,
                fontSize: isMobile ? '12px' : '20px',
                textAlign: isMobile ? 'left' : 'center', 
                fontWeight: 800, 
                color: '#CC293C'
              }}
            >
              <i>Entry Deadline: Sun Oct 13 @ 1:00pm</i>
            </Typography>
          </Box>
        </Box>
       {isMobile && (
        <img
          loading="lazy"
          alt="NFL Survivor Badge"
          src="https://poolpartyfilestorage.blob.core.windows.net/media/poolParty2ndShotSurvivor.webp"
          style={{
            height: '90px',
            width: '72px',
            position: 'relative',
            objectFit: 'cover',
          }}
        />
        )}
      </Box>
      <Box
        sx={{
          maxWidth: isMobile ? '295px' : '420px',
          margin: '0 auto',
          marginTop: isMobile ? '32px' : '80px',
        }}
      >
        <Typography
          sx={{
            fontSize: isMobile ? '14px' : '18px',
            fontWeight: 700,
            marginTop: '24px',
          }}
        >
              Let's try this again.  Your NFL Survivor {isMobile && <br />}
              Second Shot is here.
          </Typography>
          <Typography
            sx={{
              fontSize: isMobile ? '14px' : '16px',
              fontWeight: 500,
              textAlign: 'left', // Aligns the entire text to the left
              marginTop: '10px',
            }}
          >
            <span style={{ fontWeight: 700 }}>Week 6 Start Date</span> - With 5 weeks in the books already, you will have no excuse this time.
          </Typography>
          <Typography
            sx={{
              fontSize: isMobile ? '14px' : '16px',
              fontWeight: 500,
              textAlign: 'left', // Aligns the entire text to the left
            }}
          >
            <span style={{ fontWeight: 700 }}>Mike & Tony's Gryos Prize</span> - Our standalone champion will get a ticket to gyro heaven.
          </Typography>
          <Button
            variant="contained"
            onClick={() =>
              navigateToTop(`/nfl-survivor/${uriEncoded('f03ee2a7-bd82-44ee-a749-927839b678d0')}/HOME/HOME`)
            }            sx={{
              marginTop: '24px',
              width: '120px',
              whiteSpace: 'nowrap',
            }}
          >
            POOL HOME
          </Button>
      </Box>
      </Box>
      {!isMobile && (
        <Box>
        <img
          loading="lazy"
          alt="NFL Survivor Badge"
          src="https://poolpartyfilestorage.blob.core.windows.net/media/poolParty2ndShotSurvivor.webp" 
          style={{
            height: '240px',
            width: '300px',
            position: 'relative',
            objectFit: 'contain',
            marginLeft: '117px',
          }}
        />
        </Box>
      )}
      </Box>
  );
};

export default MikeAndTonysForHomePage;