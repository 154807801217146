import React, { useState, useEffect } from 'react';
import SuperPickemTwoPickCard from '../SuperPickemComponents/SuperPickemTwoPickCard';

const SuperPickemMyPicks = () => {

    return (
        <div>
            <h1>Super Pickem My Picks</h1>
            <SuperPickemTwoPickCard />
        </div>
    )
}

export default SuperPickemMyPicks;