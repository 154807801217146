import React from "react";
import { Box, Typography, Button, Modal } from "@mui/material";

const TiebreakerModal = ({ tiebreakerInfo, tiebreakInfoOpen, setTiebreakInfoOpen, handleRulesClick }) => {
    const tiebreakerDescription = tiebreakerInfo?.description;
    const tiebreakerBody = tiebreakerInfo?.body;
  

    return (
        <Modal
            open={tiebreakInfoOpen}
            onClose={() => setTiebreakInfoOpen(false)}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                outline: 'none',
            }}
        >
            <Box
                sx={{
                    width: '323px',
                    height: 'auto',
                    backgroundColor: 'white',
                    borderRadius: '4px',
                    padding: '16px',
                    boxShadow: 24,
                }}
            >
                {/* Modal content goes here */}
                <Typography
                    sx={{
                        fontSize: '16px',
                        fontWeight: '800',
                        textAlign: 'center',
                    }}
                >
                    Tiebreaker Info
                </Typography>
                <Box
                    sx={{
                        fontSize: '12px',
                        fontWeight: '500',
                        textAlign: 'center',
                        marginTop: '10px',
                    }}
                >
                    {tiebreakerBody.map((group, index) => (
                        <div key={index} style={{ marginBottom: '10px' }}>
                            {Array.isArray(group.lines) ? (
                                group.lines.map((line, lineIndex) => (
                                    <span key={lineIndex} style={{ display: 'block' }}>
                                        {line}
                                    </span>
                                ))
                            ) : (
                                <span style={{ display: 'block' }}>
                                    {group.lines}
                                </span>
                            )}
                        </div>
                    ))}
                </Box>

                <Box>
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: '500',
                            textAlign: 'center',
                            marginTop: '20px',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        <span style={{ display: 'block', marginTop: '10px' }}>
                            <span style={{ fontWeight: 700 }}>
                                Tiebreaker:
                            </span> {tiebreakerDescription}
                        </span>
                    </Typography>
                </Box>
                <Typography
                    sx={{
                        fontSize: '12px',
                        fontWeight: '500',
                        textAlign: 'center',
                        marginTop: '10px',
                    }}
                >
                    See the pool{' '}
                    <span
                        style={{
                            textDecoration: 'underline',
                            color: '#00AAD1',
                            cursor: 'pointer',
                            fontWeight: '700',
                        }}
                        onClick={handleRulesClick}
                    >
                        RULES
                    </span>
                    {' '}for complete details!
                </Typography>

                <Button
                    sx={{
                        backgroundColor: '#00AAD1',
                        height: '38px',
                        width: '115px',
                        color: 'white',
                        display: 'block',
                        margin: '0 auto',
                        marginTop: '20px',
                        '&:hover': {
                            backgroundColor: '#00AAD1',
                        },
                        '&:focus': {
                            outline: 'none',
                        },
                        '&:active': {
                            backgroundColor: '#00AAD1',
                        },
                    }}
                    onClick={() => setTiebreakInfoOpen(false)}
                >
                    OK
                </Button>

            </Box>
        </Modal>
    );
}

export default TiebreakerModal;
