import React, { useEffect} from "react";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Select, MenuItem, FormControl } from '@mui/material';


const QuickPickLeaderboardDropdown = ({ 
    interval, 
    setInterval, 
    seasonText,
    currentInterval,
    firstInterval,
    lastInterval,
    intervalType,
 }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    //console.log("Interval", interval);
    // Set the interval to `firstInterval` on mount if it's not set to start with the correct value
    useEffect(() => {
        //console.log("Running DropDown UseEffect");
        
        // Prevent execution if the interval is 'Season Standings'
        if (interval === 'Season Standings') {
            return;
        }
    
        if (firstInterval > currentInterval) {
            setInterval(firstInterval);
        } else {
            setInterval(currentInterval);
        }
    }, []); // Still runs on mount as the dependency array is empty
    

    const weekOptions = [];
    if (intervalType === 'Week') {
        // Start from the `firstInterval` and go to `currentInterval`, handling both ascending and descending ranges.
        if (firstInterval <= currentInterval) {
            for (let i = firstInterval; i <= currentInterval; i++) {
                weekOptions.push(
                    <MenuItem key={i} value={i}>
                        Week {i}
                    </MenuItem>
                );
            }
        } else {
                weekOptions.push(
                    <MenuItem key={firstInterval} value={firstInterval}>
                        Week {firstInterval}
                    </MenuItem>
                );
        }
    }

    
    //console.log("Week Options", weekOptions);

    const handleChange = (event) => {
        const value = event.target.value;
        setInterval(value);
        //console.log("Season vs Week DROPDOWN", value); // Add this log
    };

    return (
        <FormControl
            variant="outlined"
            size="small"
            style={{ 
                marginTop: isMobile ? '15px' : '15px',
                marginBottom: isMobile ? '15px' : '15px',
                width: isMobile ? '163px' : '200px' 
            }}
        >
            <Select
                value={interval}
                onChange={handleChange}
                style={{
                    textAlign: "center",
                    fontSize: isMobile ? '12px' : '14px',
                    padding: isMobile ? '5px' : '10px',
                    height: isMobile ? '24px' : '42px',
                    border: "1px solid #000000",
                    borderRadius: "4px",
                    boxShadow: "none", // Remove default shadow if you want a flat look
                    width: '100%'
                }}
            >
                {seasonText && <MenuItem value={seasonText}>{seasonText}</MenuItem>}
                {weekOptions?.reverse()?.map((option, index) => (
                    <MenuItem key={index} value={option.props.value}>{option.props.children}</MenuItem>
                ))}
                
            </Select>
        </FormControl>
    );
}

export default QuickPickLeaderboardDropdown;