import React from "react";
import { useAdminDashContext } from "../context/AdminDashContext";
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";

const DashHome = () => {
    const {
        pageState,
        setPageState,
        leagueState,
        setLeagueState,
        quickPickLeagues
    } = useAdminDashContext();

    const navigateToTop = useNavigateToTop();

    const handleClick = (page, league) => {
        console.log(page, league);
        setPageState(page);
    
        if (league) {
            setLeagueState(league);
            // Include league as a query parameter in the URL
            navigateToTop(`/admin-dash-test/${page}?league=${league}`);
        } else {
            // Navigate without the league parameter
            navigateToTop(`/admin-dash-test/${page}`);
        }
    };
    

    return (
        <div>
            {/* Admin Dash Home Header */}
            <h1 style={{ fontSize: '36px', fontWeight: 700, marginBottom: '20px' }}>
                Admin Dash Home
            </h1>

            {/* Game Creation or Editing */}
            <h2 style={{ fontSize: '24px', fontWeight: 700, textDecoration: 'underline', marginBottom: '5px' }}>
                Games
            </h2>
            <ul>
                <li 
                    style={{ textDecoration: 'underline', cursor: 'pointer'}}
                    onClick={() => handleClick('CreateGames')}    
                >
                    Create Games
                </li>
                <li 
                    style={{ textDecoration: 'underline', cursor: 'pointer'}}
                    onClick={() => handleClick('EditGames')}    
                >
                    Edit Games
                </li>
            </ul>

            {/* Quick Pick Game Section */}
            <h2 style={{ fontSize: '24px', fontWeight: 700, textDecoration: 'underline', marginBottom: '5px', marginTop: '20px' }}>
                Quick Pick Games
            </h2>

            <ul>
                {quickPickLeagues.map((league) => (
                    <li 
                        key={league}
                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={() => handleClick('QuickPick', league)} // Correctly pass `league` to handleClick
                    >
                        {league}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default DashHome;
