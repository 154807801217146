import React, { useState, useEffect } from 'react';
import { Paper, Box, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Typography } from '@mui/material';
//import { useBirdieContext } from '../context/BirdiePoolContext';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../NFLPickEmStyles/PickEmLeaderboardStyles.scss';
import PickEmLeaderboardDropdown from '../DropdownMenus/PickEmLeaderboardDropdown';
import { useNFLPickEmContext } from '../context/NFLPickEmContext';
import { DateTime } from 'luxon';
import CloseIcon from '@mui/icons-material/Close';


const TeamStats = ({ handleClickState, leaderboardData }) => {

  const { 
    startingWeekState, 
    NFLTeams, 
    NFLScoresBySeason,
    partyState
 } = useNFLPickEmContext();

  const [sortBy, setSortBy] = useState('timesPicked');
  const [sortOrder, setSortOrder] = useState('desc');
  const [seasonVsWeek, setSeasonVsWeek] = useState(`Week ${startingWeekState}`);
  //console.log("Season vs Week TEAM STATS", seasonVsWeek);

  useEffect(() => {
    setSortBy('timesPicked');
    setSortOrder('desc');
  }, [seasonVsWeek, partyState]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const teamLogo = (teamId) => {
    return NFLTeams?.find(team => team.GlobalTeamID === teamId)?.WikipediaLogoURL;
  };

  const teamKey = (teamId) => {
    return NFLTeams?.find(team => team.GlobalTeamID === teamId)?.Key;
  };

  const teamName = (teamId) => {
    return NFLTeams?.find(team => team.GlobalTeamID === teamId)?.Name;
  };

  const nflOpponentInfo = (gameId, teamId) => {
    const game = NFLScoresBySeason?.find(game => game.GlobalGameID === gameId);
    const isPickHome = game?.GlobalHomeTeamID === teamId;
    const opponentKey = isPickHome ? teamKey(game?.GlobalAwayTeamID) : teamKey(game?.GlobalHomeTeamID);
    const leadingSymbol = isPickHome ? 'vs' : '@';
    const displayString = `${leadingSymbol} ${opponentKey}`;
    return displayString;
  };

  // Current time in New York timezone
  const timeInNYNow = DateTime.now().setZone('America/New_York');
    
  //const dummyDate = DateTime.fromISO('2024-09-12T12:00:00.000-05:00').setZone('America/New_York');
  //console.log("DUMMY DATE", dummyDate);
  //console.log("Leaderboard Data", leaderboardData); 
  

  function teamStatsData() {
    const teamStats = [];
    const allFilteredPicks = [];

    if (seasonVsWeek !== 'Season') {
        const selectedWeek = seasonVsWeek?.replace(/\D/g, '');
        for (let i = 0; i < leaderboardData?.length; i++) {
            // Ensure userPicks is an array
            const userPicks = leaderboardData[i]?.picks ?? [];
            const filteredPicks = userPicks?.filter(pick => 
              String(pick.week) === selectedWeek &&
              (DateTime.fromISO(pick.dateTime) < timeInNYNow)
          );
                      //console.log("FILTERED PICKS", filteredPicks);
            allFilteredPicks.push(...filteredPicks);
        }
    } else {
        for (let i = 0; i < leaderboardData?.length; i++) {
            // Ensure userPicks is an array
            const userPicks = leaderboardData[i]?.picks ?? [];
            const filteredPicks = userPicks?.filter(pick =>
              String(pick.week) <= startingWeekState &&
              (DateTime.fromISO(pick.dateTime) < timeInNYNow)
          );
                      allFilteredPicks.push(...filteredPicks);
        }
    }

    teamStats.push({ filteredPicks: allFilteredPicks });
    return teamStats;
}

  const teamStats = teamStatsData();
  //console.log("TEAM STATS", teamStats);

  function compileTeamStats(filteredPicks) {
    const teamStats = {};
    let overallStats = {
        totalWins: 0,
        totalLosses: 0,
        totalPushes: 0,
        totalPendings: 0
    };

    filteredPicks.forEach(pick => {
        const { gameId, teamId, spread, dateTime, result } = pick;

        if (!teamStats[teamId]) {
            teamStats[teamId] = {
                gameId: gameId,
                teamId: teamId,
                timesSelected: 0,
                spreads: new Set(), // using Set to keep unique spreads
                dateTime: dateTime, // assuming dateTime is the same for all picks of the same team
                result: {
                    Win: 0,
                    Loss: 0,
                    Push: 0,
                    Pending: 0
                }
            };
        }

        // Increment the times selected
        teamStats[teamId].timesSelected += 1;

        // Add the spread to the set of spreads (Set ensures uniqueness)
        teamStats[teamId].spreads.add(spread);

        // Increment the result count
        if (result === 'Win' || result === 'Loss' || result === 'Push' || result === 'Pending') {
            teamStats[teamId].result[result] += 1;

            // Update overall stats
            if (result === 'Win') overallStats.totalWins += 1;
            if (result === 'Loss') overallStats.totalLosses += 1;
            if (result === 'Push') overallStats.totalPushes += 1;
            if (result === 'Pending') overallStats.totalPendings += 1;
        } else {
            teamStats[teamId].result.Pending += 1;
            overallStats.totalPendings += 1;
        }

        // Add Team Key for sorting purposes
        const teamName = teamKey(teamId);
        teamStats[teamId].teamKey = teamName;

        // Calculate the Overall Result
        let overallResult;
        let poolRecordColor = '002129';
        const winCount = teamStats[teamId].result.Win;
        const lossCount = teamStats[teamId].result.Loss;
        const pushCount = teamStats[teamId].result.Push;
        const pendingCount = teamStats[teamId].result.Pending;

        // Calculate win percentage
        const totalGames = winCount + lossCount + pushCount;
        const winPercentage = totalGames > 0 ? ((winCount + (pushCount * 0.5)) / totalGames) * 100 : 0;

        // Add win percentage to the teamStats object
        teamStats[teamId].winPercentage = isNaN(winPercentage) ? ' - ' : winPercentage.toFixed(2) + '%';
        
        if (winCount > 0 && lossCount === 0 && pushCount === 0 && pendingCount === 0) {
            overallResult = "Win";
            poolRecordColor = '#00AA72';
        } else if (winCount === 0 && lossCount > 0 && pushCount === 0 && pendingCount === 0) {
            overallResult = "Loss";
            poolRecordColor = '#CC293C';
        } else if (winCount === 0 && lossCount === 0 && pushCount > 0 && pendingCount === 0) {
            overallResult = "Push";
        } else if (winCount === 0 && lossCount === 0 && pushCount === 0 && pendingCount > 0) {
            overallResult = "Pending";
        } else {
            overallResult = "Split";
        }
        teamStats[teamId].overallResult = overallResult;
        teamStats[teamId].poolRecordColor = poolRecordColor;    
    });

    // Convert spreads Set back to an array for each team
    for (const teamId in teamStats) {
        teamStats[teamId].spreads = Array.from(teamStats[teamId].spreads);
    }

    // Return both teamStats and overallStats
    return { teamStats, overallStats };
}


    // Assuming teamStatsData returns an array with a single object as we did before
    const teamStatsArray = teamStatsData();
    const filteredPicks = teamStatsArray[0]?.filteredPicks || [];
    const gatherCompiledTeamStats = compileTeamStats(filteredPicks);
    const {teamStats: compiledTeamStats, overallStats} = gatherCompiledTeamStats;
    console.log("Compiled Team Stats", compiledTeamStats, "Overall Stats", overallStats);

    // Convert the compiledTeamStats object to an array
    const teamStatsArrayForSorting = Object.values(compiledTeamStats);

    
    // Sort the array by timesSelected
    const sortedTeamStats = teamStatsArrayForSorting.sort((a, b) => b.timesSelected - a.timesSelected);

    //console.log("SORTED TEAM STATS", sortedTeamStats);




  const handleSort = (columnName) => {
    if (sortBy === columnName) {
      // Toggle sorting order if the same column is clicked again
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // Sort by the clicked column
      setSortBy(columnName);
      if (columnName === 'timesPicked' || columnName === 'result' || columnName === 'winPercentage') {
        // For player name, always start with ascending order
        setSortOrder('desc');
      } else {
        // For other columns, use the current sort order
        setSortOrder('asc');
      }
    }
  };

  const sortedData = [...sortedTeamStats].sort((a, b) => {
    if (sortBy === 'timesPicked') {
      return sortOrder === 'asc' ? a.timesSelected - b.timesSelected : b.timesSelected - a.timesSelected;
    } else if (sortBy === 'teamKey') {
      const aTeamKey = a.teamKey || 'No Pick';
      const bTeamKey = b.teamKey || 'No Pick';
      return sortOrder === 'asc' ? aTeamKey.localeCompare(bTeamKey) : bTeamKey.localeCompare(aTeamKey);
    } else if (sortBy === 'result') {
      return sortOrder === 'asc' ? a.overallResult.localeCompare(b.overallResult) : b.overallResult.localeCompare(a.overallResult);
    } else if (sortBy === 'winPercentage') {
      const aWinPercentage = a.winPercentage === ' - ' ? -1 : parseFloat(a.winPercentage);
      const bWinPercentage = b.winPercentage === ' - ' ? -1 : parseFloat(b.winPercentage);
  
      return sortOrder === 'asc' ? aWinPercentage - bWinPercentage : bWinPercentage - aWinPercentage;
    }
  });
  
  //console.log("SORTED DATA", sortedData);
  

  const timesPickedColor = sortBy === 'timesPicked' ? '#FFC60A' : '#fff';
  const teamKeyColor = sortBy === 'teamKey' ? '#FFC60A' : '#fff';
  const resultColor = sortBy === 'result' ? '#FFC60A' : '#fff';
  const winPercentageColor = sortBy === 'winPercentage' ? '#FFC60A' : '#fff';

  //console.log("IS MOBILE", isMobile, seasonVsWeek);

  return (
    <Box>
      <PickEmLeaderboardDropdown seasonVsWeek={seasonVsWeek} setSeasonVsWeek={setSeasonVsWeek} seasonPlain={true}/>
      {sortedData?.length > 0 && (
        <Typography
          sx={{
            fontSize: isMobile ? '14px' : '18px',
            fontWeight: 700,
            marginBottom: '20px',
          }}
        >
          Pool Wide Record: {overallStats.totalWins}-{overallStats.totalLosses}-{overallStats.totalPushes}
        </Typography>
      )}
      <TableContainer component={Paper} style={{ overflowX: "clip", maxWidth:'930px', margin:'0 auto', marginBottom: '30px', borderRadius: '0' }}>
        <Table>
          <TableHead sx={{ backgroundColor: '#002129' }}>
            <TableRow>
              <TableCell
                className="pickem-leaderboard-table-cells"
                onClick={() => handleSort('timesPicked')}
                sx={{ width: isMobile ? '76px' : '254px', color: timesPickedColor, fontWeight: 700, textAlign: 'center', position: 'relative', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px', textDecoration: 'underline'}}
              >
                TIMES PICKED
              </TableCell>
              <TableCell
                className="pickem-leaderboard-table-cells"
                onClick={() => handleSort('teamKey')}
                sx={{ width: isMobile ? '140px' : '422px', color: teamKeyColor, fontWeight: 700, textAlign: 'center', position: 'relative', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px', textDecoration: 'underline'}}
              >
                TEAM
              </TableCell>
              {(!isMobile || (isMobile && seasonVsWeek !== 'Season')) && (
              <TableCell
                className="pickem-leaderboard-table-cells"
                onClick={() => handleSort('result')}
                sx={{ width: isMobile ? '131px' : '254px', color: resultColor, fontWeight: 700, textAlign: 'center', position: 'relative', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px', textDecoration: 'underline'} }
              >
                POOL RESULTS
              </TableCell>
              )}
              {seasonVsWeek === 'Season' && (
              <TableCell
                className="pickem-leaderboard-table-cells"
                onClick={() => handleSort('winPercentage')}
                sx={{ width: isMobile ? '131px' : '179px', color: winPercentageColor, fontWeight: 700, textAlign: 'center', position: 'relative', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px', textDecoration: 'underline'} }
              >
                WIN %
              </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map((team, index) => {
              return (
                <TableRow 
                  key={index}
                  sx={{
                    backgroundColor: index % 2 === 0 ? '#FFF' : '#F0F1F1',
                  }}
                >
                  <TableCell className="pickem-leaderboard-table-cells" style={{ color: (seasonVsWeek === 'Season' || team.overallResult === "Split") ? '#002129' : team.poolRecordColor, textAlign: 'center', fontSize: isMobile ? '14px' : '18px', fontWeight: '500' }}>{team.timesSelected}</TableCell>
                  <TableCell className="pickem-leaderboard-table-cells" style={{ fontSize: '12px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                    {team.teamId === "No Pick" ? (
                      <CloseIcon
                          sx={{
                              width: isMobile ? '32px' : '40px',
                              height: isMobile ? '32px' : '40px',
                              marginLeft: isMobile ? '0px' : '35px',
                              color: '#CC293C' // Optional: Set the color of the CloseIcon if needed
                          }}
                      />
                  ) : (
                      <img
                          src={teamLogo(team.teamId)}
                          alt="Team Logo"
                          style={{
                              width: isMobile ? '32px' : '40px',
                              height: isMobile ? '32px' : '40px',
                              marginLeft: isMobile ? '0px' : '35px',
                              objectFit: 'contain'
                          }}
                      />
                  )}
                      <div style={{ marginLeft: '8px', whiteSpace: 'nowrap', color: '#002129', fontSize: isMobile ? '14px' : '18px', fontStyle: 'normal', fontWeight: 500 }}>
                      {team.teamId === "No Pick" ? (
                          <span style={{ color: team.poolRecordColor }}>Missed Pick</span>
                      ) : (
                        <span style={{ color: (seasonVsWeek === 'Season' || team.overallResult === "Split") ? '#002129' : team.poolRecordColor }}>
                          {(seasonVsWeek !== 'Season' && isMobile) 
                            ? teamKey(team.teamId) 
                            : `${teamKey(team.teamId)} ${teamName(team.teamId)}`
                          }
                        </span>

                      )}
                  </div>
                  {team.teamId !== "No Pick" && seasonVsWeek !== 'Season' && (
                      <div 
                          style={{ 
                              marginLeft: '8px', 
                              whiteSpace: 'nowrap', 
                              color: '#002129', 
                              fontSize: isMobile ? '12px' : '16px', 
                              fontStyle: 'normal', 
                              fontWeight: 500 
                          }}
                      >
                          <span style={{ color: team.overallResult === "Split" ? '#002129' : team.poolRecordColor}}>
                            {nflOpponentInfo(team.gameId, team.teamId)}
                          </span>
                      </div>
                  )}
                      </div>
                    </TableCell>
                      {(!isMobile || (isMobile && seasonVsWeek !== 'Season')) && (
                        <TableCell className="pickem-leaderboard-table-cells" style={{ textAlign: 'center' }}>
                          {seasonVsWeek !== 'Season' && (
                            <div style={{ color: team.overallResult === "Split" ? '#002129' : team.poolRecordColor, textAlign: 'center', fontFamily: 'Inter', fontSize: isMobile ? '14px' : '18px', fontStyle: 'normal', fontWeight: 500, lineHeight: 'normal', textTransform: 'uppercase', }}>
                              {team.overallResult}
                            </div>
                          )}
                          {(seasonVsWeek === 'Season' || team.overallResult === 'Split') && (
                            <div style={{ color: '#1E1E1E', fontFamily: 'Inter', fontSize: isMobile ? '14px' : '18px', fontStyle: 'normal', fontWeight: 500, lineHeight: 'normal' }}>
                              {seasonVsWeek === 'Season' ? `${team.result.Win}-${team.result.Loss}-${team.result.Push}` : `(${team.result.Win}-${team.result.Loss}-${team.result.Push})`}
                            </div>
                          )}
                        </TableCell>
                      )}
                    {seasonVsWeek === 'Season' && (
                      <TableCell className="pickem-leaderboard-table-cells" style={{ textAlign: 'center' }}>
                      <div style={{ color: '#1E1E1E', fontFamily: 'Inter', fontSize: isMobile ? '14px' : '18px', fontStyle: 'normal', fontWeight: 500, lineHeight: 'normal' }}>
                        {team.winPercentage}
                      </div>
                    </TableCell>
                    
                    )}
                  </TableRow>
                );
              })}
              {sortedData?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={4} style={{ textAlign: 'center', padding: '20px', color: '#002129' }}>
                    <Typography
                        sx={{
                            fontSize: isMobile ? '16px' : '20px',
                            fontWeight: 700,
                            marginBottom: '20px'
                        }}
                    >
                        {isMobile
                            ? (
                                <>
                                    Team Stats will be available upon the <br />
                                    game start for each team.
                                </>
                            )
                            : 'Team Stats will be available upon the game start for each team.'
                        }
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: isMobile ? '16px' : '20px',
                            fontWeight: 700,
                        }}
                    >
                        {isMobile
                            ? (
                                <>
                                    Only teams that have been picked will <br />
                                    appear in this table.
                                </>
                            )
                            : 'Only teams that have been picked will appear in this table.'
                        }
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

  );
};

export default TeamStats;