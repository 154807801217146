import React from 'react';
import { useParams } from 'react-router-dom';
import LoginComponent from '../components/login/login';

const Login = () => {
  const { '*': redirectPath } = useParams(); // Captures everything after /login/

  return <LoginComponent redirectPath={redirectPath} />;
};

export default Login;
