import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";
import { useQuickPickNFLContext } from "./context/QuickPickNFLContext";
import GameRulesPage from "../../GameRulesPage/GameRulesPage";
import GameHomePage from "../../GameHomePage/GameHomePage";
import GameSponsorHeader from "../../PoolHeaderBar/GameSponsorHeader";
import DynamicPoolHeaderBar from "../../PoolHeaderBar/DynamicPoolHeaderBar";
import MyPicks from "../MyPicks/MyPicks";
import QuickPickNav from "../QuickPickNav/QuickPickNav";
import JoinGameModal from "../../GameModals/JoinGameModal/JoinGameModal";
import MyParty from "../../MyParty/MyParty";
import SponsorTab from "../../SponsorTab/SponsorTab";
import QuickPickLeaderboard from "../Leaderboard/QuickPickLeaderboard";
import QuickPickUserDash from "../UserDash/QuickPickUserDash";
import QuickPickPickStats from "../PickStats/QuickPickPickStats";
import QuickPickRewards from "../Rewards/QuickPickRewards";
import RewardsRedeemHeader from "../Rewards/RewardsRedeemHeader";
import TestPropBuilder from "./TestPropBuilder";
import { DateTime } from "luxon";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";


const QuickPickNFLContainer = () => {
    const {
        signUpState,
        setSignUpState, 
        gameAddress, 
        userData, 
        gameData,
        interval, 
        setInterval, 
        currentInterval, 
        firstInterval,
        lastInterval,
        userSubgroups, 
        doesUserBelong,
        myUserId,
        myUsername,
        userPicksFromDB,
        pickState,
        setPickState,
        partyState,
        setPartyState,
        partyId,
        setPartyId,
        setDefaultPartyId,
        setDefaultPartyName,
        gameType,
        mainGameId,
        joinOpen,
        setJoinOpen,
        isGameOver,
        hasJoinDeadlinePassed,
        clickState,
        setClickState,
        gameName,
        nflStandings,
        nflScoresBySeason,
        nflTeams,
        quickPickNFLGames,
        gatherGameInfo,
        gatherDisplayBarInfo,
        teamsToInclude,
        gamePropsToUse,
        selectedTeamPropsToUse,
        opponentTeamPropsToUse,
        leaderboardInfo,
        defaultPartyId,
        defaultPartyName,
        firstSubgroupId,
        firstSubgroupName,
        handleCreateSubgroup,
        picksPerInterval,
        usingTiebreaker,
        league,
        intervalType,
        currentPropIds,
        propIdsToUse,
        rewards,
        joinReward,
        gameRewards,
        formattedGameStartTime,
        howManyWinners,
        oneWeekEvent,
        tiebreakerInfo,
        joinModalText,
    } = useQuickPickNFLContext();
    //console.log("CONTAINER USER DATA", userData, "has Join Passed", hasJoinDeadlinePassed, "Does User Belong: ", doesUserBelong);
    
    const { component, pageState, optionalState } = useParams();
    //console.log("PAGE AND OPTIONAL STATE", pageState, optionalState);
    const navigate = useNavigate();
    const location = useLocation();

    //console.log("Rewards in Container:", rewards);
    const timeNowInNewYork = DateTime.now().setZone('America/New_York');
    const anyUnredeemedRewards = rewards?.some(reward => 
        !reward.redeemed && DateTime.fromISO(reward.expiration) > timeNowInNewYork
    );
    
    //console.log("Any Unredeemed Rewards in Container: ", anyUnredeemedRewards);

    const [leaderboardForRanking, setLeaderboardForRanking] = useState([]);

    const assignRanks = (data) => {
      // Sort data in descending order by totalPoints and then by totalMargin
      data?.sort((a, b) => {
        const aPoints = a.totalPoints ?? 0;
        const bPoints = b.totalPoints ?? 0;
        const aMargin = a.totalMargin ?? 0;
        const bMargin = b.totalMargin ?? 0;
    
        // Sort by points first, then by margin (descending for both)
        if (aPoints !== bPoints) {
          return bPoints - aPoints; // Descending order for points
        } 
        return aMargin - bMargin; // Descending order for margin
      });
    
      let rank = 1;
      let tiedRank = 1;
      let previousPoints = data[0]?.totalPoints ?? 0;
      let previousMargin = data[0]?.totalMargin ?? 0;
    
      return data.map((entry, index) => {
        const currentPoints = entry.totalPoints ?? 0;
        const currentMargin = entry.totalMargin ?? 0;
    
        // Determine rank for tied entries
        if (currentPoints === previousPoints && currentMargin === previousMargin) {
          entry.rank = tiedRank;
        } else {
          rank = index + 1; // Update rank to current index + 1
          tiedRank = rank; // Update tiedRank to current rank
          entry.rank = rank; // Assign current rank to entry
        }
    
        // Update previous values for next iteration
        previousPoints = currentPoints;
        previousMargin = currentMargin;
    
        return entry; // Return the updated entry
      });
    };
    
  
  
    useEffect(() => {
      //console.log("Running Leaderbaord Effect in Container");
      if (leaderboardInfo) {
        let rankedData = assignRanks(leaderboardInfo);
        setLeaderboardForRanking(rankedData);
      }
    }, [leaderboardInfo]);
    //console.log("RANKED DATA in Conttainer", leaderboardForRanking);
    const myRank = leaderboardForRanking?.find(user => user.userId === myUserId)?.rank || null;
    const amITied = leaderboardForRanking?.some(user => user.rank === myRank && user.userId !== myUserId); 
    const totalEntries = leaderboardForRanking?.length;
    //console.log("My Rank in Container", myRank, "Am I tied:", amITied, "Total Entries in Container", totalEntries);

    const whosInFirst = leaderboardForRanking[0]?.username;
    const whosInFirstCharacterCount = whosInFirst?.length;
    const whosInSecond = leaderboardForRanking[1]?.username;
    const whosInSecondCharacterCount = whosInSecond?.length;
    const whosInThird = leaderboardForRanking[2]?.username;
    const whosInThirdCharacterCount = whosInThird?.length;

    const homePageProps = {gameAddress, userData, gameData, setInterval, currentInterval, userSubgroups, doesUserBelong, myUserId, partyState, setPartyState, partyId, setPartyId, setDefaultPartyId, setDefaultPartyName, gameType, mainGameId, joinOpen, setJoinOpen, whosInFirst, whosInFirstCharacterCount, whosInSecond, whosInSecondCharacterCount, whosInThird, whosInThirdCharacterCount, isGameOver, hasJoinDeadlinePassed, howManyWinners}
    useEffect(() => {
      // Update the clickState whenever the URL changes
      if (component) {
        setClickState(component);
      }
    }, [component, location]);

    const uriEncoded = (link) => {
      return encodeURI(`${encodeURIComponent(link)}`);
    }

    const logo = gameData?.clubLogo;
    const sponsorSecondaryLogo = gameData?.sponsorInfo?.sponsorSecondaryLogo;
    const sponsorLogo = gameData?.sponsorInfo?.sponsorLogo;
    const sponsorHeaderLogo = gameData?.sponsorInfo?.sponsorHeaderLogo;
    //console.log("Sponsor Header Logo", sponsorHeaderLogo);
    const sponsorBannerText = gameData?.sponsorInfo?.sponsorBannerText;
    const sponsorName = gameData?.sponsorInfo?.sponsorName;
    const sponsorAbbreviation = gameData?.sponsorInfo?.sponsorAbbreviation;

    const selectedGames = nflScoresBySeason?.filter(game => game.Week === interval);
    const selectedQuickPickGames = quickPickNFLGames?.filter(game => game.Week === interval);
    
    function getVoidedPicksIds(games) {
      const voidedIds = [];
  
      games?.forEach(game => {
          const propCategories = ['tiebreakers', 'gameProps', 'homeProps', 'awayProps'];
  
          propCategories?.forEach(category => {
              if (game?.props[category]) {
                  game?.props[category].forEach(prop => {
                      if (prop?.correctOption === 'Void') {
                          voidedIds.push(prop.id);
                      }
                  });
              }
          });
      });
  
      return voidedIds;
    }
  
    const voidedPicksIds = getVoidedPicksIds(selectedQuickPickGames);
    const voidedPicksCount = voidedPicksIds?.length;
    console.log("Voided Picks Ids: ", voidedPicksIds, "Length: ", voidedPicksCount);
    
    let fullLeaderboard = leaderboardInfo ? leaderboardInfo : [];
    const [leaderboardData, setLeaderboardData] = useState([]);
    const [unsavedPicks, setUnsavedPicks] = useState(false);
    //console.log("Unsaved Picks", unsavedPicks);

    useEffect(() => {
      //console.log("Running Use Effect, resetting unsaved Picks");
      if (mainGameId) {
      setUnsavedPicks(false);
      }
    }, [mainGameId]);

    // useEffect(() => {
    //   if (partyId !== mainGameId) {
    //     //console.log("PartyId in Container: ", partyId, gameData);
    //     const subgroups = gameData?.subGroups
    //     const filteredSubgroups = subgroups?.filter(subgroup => subgroup.groupID === partyId);
    //     const filteredUserIds = filteredSubgroups?.[0]?.groupMembers;
    //     //console.log("Chosen Subgroup in Container: ", subgroups, filteredSubgroups, filteredUserIds);
    //     const filteredLeaderboard = fullLeaderboard?.filter(user => filteredUserIds?.includes(user.userId));
    //     //console.log("Filtered Leaderboard in Container: ", filteredLeaderboard);
    //     setLeaderboardData(filteredLeaderboard);
    //   } else {
    //     setLeaderboardData(fullLeaderboard);
    //   }
    //   }, [partyId, partyState, fullLeaderboard]);

    useEffect(() => {
      console.log("Leaderboard Setting")
      if (fullLeaderboard && fullLeaderboard.length > 0 && leaderboardData !== fullLeaderboard) {
          setLeaderboardData(fullLeaderboard);
      }
  }, [fullLeaderboard]);
      

      const navigateToTop = useNavigateToTop();

  const handleClickState = (click) => {
    setClickState(click);
    let pageStateToUse = partyId;
    if (click === 'MY_PICKS') {
      pageStateToUse = 'CURRENT';
      setInterval(currentInterval);
      setPartyState(defaultPartyName);
      setPartyId(defaultPartyId);
    } else if (click === 'HOME') {
      pageStateToUse = 'HOME';
      setPartyState(defaultPartyName);
      setPartyId(defaultPartyId);
    }
    // if (click === 'MY_PARTY' && userSubgroups.length === 0) {
    //   pageStateToUse = 'NO_PARTY';
    // } else if (click === 'MY_PARTY' && partyId === mainGameId) {
    //   if (defaultPartyName !== 'Pool Party') {
    //     pageStateToUse = defaultPartyId;
    //     setPartyState(defaultPartyName);
    //     setPartyId(defaultPartyId);
    //   } else {
    //     pageStateToUse = firstSubgroupId;
    //     setPartyState(firstSubgroupName);
    //     setPartyId(firstSubgroupId);
    //   }
    // }
    if (click === 'REWARDS') {
      pageStateToUse = 'ELIGIBLE';
    }

    if (click === 'PRESENTED_BY') {
      pageStateToUse = `${gameData?.sponsorInfo?.sponsorAbbreviation} Story`;
    }
    navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded(click)}/${uriEncoded(pageStateToUse)}`);
  }

  

  return (
    <div>
      {sponsorLogo && 
          <GameSponsorHeader 
            sponsorLogo={sponsorHeaderLogo} 
            sponsorBannerText={sponsorBannerText} 
            sponsorName={sponsorName}
            mainGameId={mainGameId}
            gameAddress={gameAddress}
            myUserId={myUserId}
            setJoinOpen={setJoinOpen}
            hasJoinDeadlinePassed={hasJoinDeadlinePassed}
            unsavedPicks={unsavedPicks}
            setUnsavedPicks={setUnsavedPicks}
            userPicksFromDB={userPicksFromDB}
            setPickState={setPickState}
            sponsorAbbreviation={sponsorAbbreviation}
          />
      }

      {anyUnredeemedRewards &&
        <RewardsRedeemHeader
          gameAddress={gameAddress}
          mainGameId={mainGameId}
        />
      }

      <DynamicPoolHeaderBar
        clickState={clickState}
        navigationText={`Home`}
        headerText={gameName}
        gameAddress={gameAddress}
        gameId={mainGameId}
        unsavedPicks={unsavedPicks}
        setUnsavedPicks={setUnsavedPicks}
        userPicksFromDB={userPicksFromDB}
        setPickState={setPickState}
      />

      {myUsername &&
        <QuickPickUserDash
          currentInterval={currentInterval}
          userPicksFromDB={userPicksFromDB}
          doesUserBelong={doesUserBelong}
          myUsername={myUsername}
          gameData={gameData}
          setJoinOpen={setJoinOpen}
          picksPerInterval={picksPerInterval}
          usingTiebreaker={usingTiebreaker}
          anyUnredeemedRewards={anyUnredeemedRewards}
          firstInterval={firstInterval}
          lastInterval={lastInterval}
          hasJoinDeadlinePassed={hasJoinDeadlinePassed}
          mainGameId={mainGameId}
          gameAddress={gameAddress}
          formattedGameStartTime={formattedGameStartTime}
          myRank={myRank}
          amITied={amITied}
          totalEntries={totalEntries}
          oneWeekEvent={oneWeekEvent}
          voidedPicksCount={voidedPicksCount}
          voidedPicksIds={voidedPicksIds}
        />
      }

      <QuickPickNav
        setClickState={handleClickState}
        clickState={clickState}
        setSignUpState={setSignUpState}
        doesUserBelong={doesUserBelong}
        isGameOver={isGameOver}
        userSubgroups={userSubgroups}
        sponsorAbbreviation={sponsorAbbreviation}
        unsavedPicks={unsavedPicks}
        setUnsavedPicks={setUnsavedPicks}
        userPicksFromDB={userPicksFromDB}
        setPickState={setPickState}
      />

      {clickState === 'HOME' && <GameHomePage homePageProps={homePageProps} /> }
        
      {clickState === 'RULES' && <GameRulesPage gameData={gameData} />}

      {clickState === 'PRESENTED_BY' && <SponsorTab gameData={gameData} />}

      {clickState === 'POOL_PICKS' &&
        <QuickPickPickStats
          leaderboardInfo={leaderboardData}
          teams={nflTeams}
          schedule={nflScoresBySeason}
          quickPickGames={quickPickNFLGames}
          firstInterval={firstInterval}
          lastInterval={lastInterval}
          currentInterval={currentInterval}
          intervalType={intervalType}
          propIdsToUse={propIdsToUse}
          teamsToInclude={teamsToInclude}
          gatherDisplayBarInfo={gatherDisplayBarInfo}
          sponsorName={sponsorName}
          league={league}
          myUserId={myUserId}
          oneWeekEvent={oneWeekEvent}
        />    
      }

      {clickState === 'LEADERBOARD' &&
        <QuickPickLeaderboard
          mainGameId={mainGameId}
          userPicksFromDB={userPicksFromDB}
          myUserId={myUserId}
          isGameOver={isGameOver}
          currentInterval={currentInterval}
          firstInterval={firstInterval}
          lastInterval={lastInterval}
          intervalType={intervalType}
          handleClickState={handleClickState}
          leaderboardData={leaderboardData}
          partyState={partyState}
          teams={nflTeams}
          schedule={nflScoresBySeason}
          gameAddress={gameAddress}
          teamsToInclude={teamsToInclude}
          gatherDisplayBarInfo={gatherDisplayBarInfo}
          sponsorName={sponsorName}
          league={league}
          oneWeekEvent={oneWeekEvent}
          tiebreakerInfo={tiebreakerInfo}
          gameName={gameName}
          quickPickGames={quickPickNFLGames}
        />
      }

      {/* {clickState === 'MY_PARTY' && 
        <MyParty
          gameData={gameData}
          myUserId={myUserId}
          handleCreateSubgroup={handleCreateSubgroup}
          userSubgroups={userSubgroups} 
          signUpState={signUpState}
          setSignUpState={setSignUpState}
          gameAddress={gameAddress}
          leaderboardData={leaderboardData}
          partyState={partyState}
          partyId={partyId}
          defaultPartyId={defaultPartyId}
          fullLeaderboard={fullLeaderboard}
          doesUserBelong={doesUserBelong}
          joinOpen={joinOpen}
          setJoinOpen={setJoinOpen}
          isGameOver={isGameOver}
        />
      } */}

      {clickState === 'REWARDS' &&
        <QuickPickRewards
          rewards={rewards}
          mainGameId={mainGameId}
          gameAddress={gameAddress}
          sponsorHeaderLogo={sponsorHeaderLogo}
          sponsorName={sponsorName}
          intervalType={intervalType}
          schedule={nflScoresBySeason}
          myUserId={myUserId} 
        />
      }

      {clickState === 'MY_PICKS' && 
        <MyPicks
          myUserId={myUserId}
          gameAddress={gameAddress} 
          mainGameId={mainGameId} 
          gameData={gameData} 
          userData={userData} 
          interval={interval} 
          setInterval={setInterval} 
          firstInterval={firstInterval} 
          lastInterval={lastInterval} 
          currentInterval={currentInterval}
          schedule={nflScoresBySeason}
          teams={nflTeams}
          standings={nflStandings}
          quickPickInfo={quickPickNFLGames}
          gatherGameInfo={gatherGameInfo}
          selectedGames={selectedGames}
          currentPropIds={currentPropIds}
          selectedQuickPickGames={selectedQuickPickGames}
          userPicksFromDB={userPicksFromDB}
          pickState={pickState}
          setPickState={setPickState}
          teamsToInclude={teamsToInclude}
          gamePropsToUse={gamePropsToUse}
          selectedTeamPropsToUse={selectedTeamPropsToUse}
          opponentTeamPropsToUse={opponentTeamPropsToUse}
          picksPerInterval={picksPerInterval}
          usingTiebreaker={usingTiebreaker}
          league={league}
          gameType={gameType}
          intervalType={intervalType}
          gatherDisplayBarInfo={gatherDisplayBarInfo}
          rewards={rewards}
          joinReward={joinReward}
          gameRewards={gameRewards}
          unsavedPicks={unsavedPicks}
          setUnsavedPicks={setUnsavedPicks}
          oneWeekEvent={oneWeekEvent}
          voidedPicksCount={voidedPicksCount}
          voidedPicksIds={voidedPicksIds}
        />
      }

      {joinOpen && (
        <JoinGameModal
            joinOpen={joinOpen}
            setJoinOpen={setJoinOpen}
            gameName={gameName}
            gameAddress={gameAddress}
            gameId={mainGameId}
            gameType={gameType}
            logo={logo}
            myUserId={myUserId}
            setSignUpState={setSignUpState}
            sponsorSecondaryLogo={sponsorSecondaryLogo}
            sponsorName={sponsorName}
            sponsorLogo={sponsorLogo}
            mainGameId={mainGameId}
            propIdsToUse={propIdsToUse}
            joinModalText={joinModalText}
        />
      )}

      {/* <TestPropBuilder /> */}
    </div>
  );
};

export default QuickPickNFLContainer;