import React, { useState, useEffect, useMemo } from "react";
import MyPicks from "./MyPicks/MyPicks";
import CFBSurvivorNav from "./Headers/CFBSurvivorNav";
import DynamicPoolHeaderBar from "../PoolHeaderBar/DynamicPoolHeaderBar";
import CFBSurvivorUserDash from "./Headers/CFBSurvivorUserDash";
import CFBSurvivorHome from "./CFBSurvivorHome/CFBSurvivorHome";
import MyParty from "../MyParty/MyParty";
import PartyDropdown from "../PartyDropdown/MyPartyDropdown";
import CFBSurvivorLeaderboard from "./CFBSurvivorLeaderboard/CFBSurvivorLeaderboard";
import CFBSurvivorTeamStats from "./CFBSurvivorTeamStats/CFBSurvivorTeamStats";
import CFBSurvivorPoolPicks from "./PoolPicks/CFBSurvivorPoolPicks";
import CFBSurvivorRules from "./Rules/CFBSurvivorRules";
import { useCFBSurvivorContext } from "./context/CFBSurvivorContext";
import { useParams, useLocation } from 'react-router-dom';
import useNavigateToTop from "../../hooks/general/useNavigateToTop";
import { DateTime } from "luxon";

const CFBSurvivorContainer = () => {
  const { component, groupStateCheck } = useParams();
  const location = useLocation();

  const {
    gameData,
    myUserId,
    mainGameId,
    gameAddress,
    handleCreateSubgroup,
    userSubgroups, 
    signUpState,
    setSignUpState,
    CFBGamesByWeek,
    setWeek,
    startingWeekState, 
    clickState,
    setClickState,
    partyState,
    setPartyState,
    partyId,
    setPartyId,
    defaultPartyId,
    defaultPartyName,
    firstSubgroupId,
    firstSubgroupName,
    leaderboardInfo,
    doesUserBelong, 
    joinOpen,
    setJoinOpen,
    teamFilter,
    setTeamFilter,
    defaultConference,
    isSurvivorOver,
  } = useCFBSurvivorContext();
  
  //console.log("PartyState in Container: ", partyState, "Party Id in Container: ", partyId);
  
  useEffect(() => {
    // Update the clickState whenever the URL changes
    if (component) {
      setClickState(component);
    }
  }, [component, location]);

  const uriEncoded = (link) => {
    return encodeURI(`${encodeURIComponent(link)}`);
  }

  // Getting the current week's Sunday 1:00 PM dealine
  const currentWeeksSchedule = CFBGamesByWeek?.filter(game => game.Week === startingWeekState);
  const currentWeeksDeadline = currentWeeksSchedule?.find(game => {
      const gameDateTime = DateTime.fromISO(game.DateTime, { zone: 'America/New_York' });
      return gameDateTime.weekday === 6 && gameDateTime.hour === 12 && gameDateTime.minute === 0;
  })?.DateTime;
  //console.log("Leaderboard Current Week's Deadline:", currentWeeksDeadline);

  const currentWeeksDeadlineDateTime = currentWeeksDeadline 
  ? DateTime.fromISO(currentWeeksDeadline, { zone: 'America/New_York' }) 
  : null;

  //console.log("LEADERBOARD INFO: ", leaderboardInfo);
  // Memoize the sorted leaderboard
  const sortedFullLeaderboard = useMemo(() => {
    return [...leaderboardInfo]?.sort((a, b) => {
      const pointsA = a?.points ?? 0; // Use default 0 if points is undefined
      const pointsB = b?.points ?? 0; // Use default 0 if points is undefined
      return pointsB - pointsA;
    });
  }, [leaderboardInfo]);
  //console.log("Sorted Full Leaderboard: ", sortedFullLeaderboard);
  

  const [leaderboardData, setLeaderboardData] = useState(sortedFullLeaderboard);

  useEffect(() => {
    if (partyId !== mainGameId) {
      const subgroups = gameData?.subGroups;
      const filteredSubgroups = subgroups?.filter(subgroup => subgroup.groupID === partyId);
      const filteredUserIds = filteredSubgroups?.[0]?.groupMembers;
      const filteredLeaderboard = sortedFullLeaderboard?.filter(user => filteredUserIds?.includes(user.userId));
      setLeaderboardData(filteredLeaderboard);
    } else {
      setLeaderboardData(sortedFullLeaderboard);
    }
  }, [partyId, partyState, gameData, sortedFullLeaderboard]);

  const navigateToTop = useNavigateToTop();

  const handleClickState = (click) => {
   
    let pageStateToUse = partyId;
    let additionalParam = '';
  
    if (click === 'MY_PICKS') {
      pageStateToUse = 'CURRENT';
      setWeek(startingWeekState);
      setPartyState(defaultPartyName);
      setPartyId(defaultPartyId);
      setTeamFilter(defaultConference);
      additionalParam = 'default';
    } else if (click === 'HOME') {
      pageStateToUse = 'HOME';
      setPartyState(defaultPartyName);
      setPartyId(defaultPartyId);
    }
    if (click === 'MY_PARTY' && userSubgroups?.length === 0) {
      pageStateToUse = 'NO_PARTY';
    } else if (click === 'MY_PARTY') {
      //console.log("PARTY ID:!!!!!!!!!!!!! ", partyId, "Default Party Name: ", defaultPartyName, "First Subgroup ID: ", firstSubgroupId);
      if (defaultPartyName !== 'Pool Party') {
        pageStateToUse = defaultPartyId;
        setPartyState(defaultPartyName);
        setPartyId(defaultPartyId);
      } else {
        pageStateToUse = firstSubgroupId;
        setPartyState(firstSubgroupName);
        setPartyId(firstSubgroupId);
      }
    }
    setClickState(click);
  
    const url = `/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded(click)}/${uriEncoded(pageStateToUse)}${additionalParam ? '/' + uriEncoded(additionalParam) : ''}`;
    navigateToTop(url);
  }

  const howManyLeftOnLeaderboard = leaderboardData?.filter(user => user.isUserEliminated === false).length;
  //console.log("How Many Left on Leaderboard: LEADERBOARD", howManyLeftOnLeaderboard);
  
  return (
    <div>
      <DynamicPoolHeaderBar 
        clickState={clickState}
        navigationText="CFB Survivor Home"
        headerText="CFB Power 4 Survivor"
        gameAddress={gameAddress}
        gameId={mainGameId}
      />
      
      {myUserId && <CFBSurvivorUserDash currentWeeksDeadlineDateTime={currentWeeksDeadlineDateTime}/>}

      <CFBSurvivorNav 
        setClickState={handleClickState} 
        clickState={clickState} 
        setSignUpState={setSignUpState}
        doesUserBelong={doesUserBelong}
        isSurvivorOver={isSurvivorOver}
        userSubgroups={userSubgroups}
      />

      {(userSubgroups?.length > 0 && ((clickState === 'MY_PARTY' && !signUpState) || clickState === 'LEADERBOARD' || clickState === 'POOL_PICKS' || clickState === "TEAM_STATS")) ?
      <PartyDropdown 
        userSubgroups={userSubgroups} 
        gameAddress={gameAddress}
        partyState={partyState}
        setPartyState={setPartyState}
        clickState={clickState}
        setPartyId={setPartyId}
      /> 
        : 
      null}
      
      {clickState === 'MY_PICKS' && <MyPicks />}

      {clickState === 'HOME' && <CFBSurvivorHome />}

      {clickState === 'RULES' && <CFBSurvivorRules />}

      {clickState === 'LEADERBOARD' && <CFBSurvivorLeaderboard leaderboardData={leaderboardData} currentWeeksDeadlineDateTime={currentWeeksDeadlineDateTime}/>}

      {clickState === 'TEAM_STATS' && <CFBSurvivorTeamStats leaderboardData={leaderboardData} currentWeeksDeadlineDateTime={currentWeeksDeadlineDateTime} />}

      {clickState === 'POOL_PICKS' && <CFBSurvivorPoolPicks leaderboardData={leaderboardData} currentWeeksDeadlineDateTime={currentWeeksDeadlineDateTime} />}

      {clickState === 'MY_PARTY' && <MyParty 
        gameData={gameData}
        myUserId={myUserId}
        handleCreateSubgroup={handleCreateSubgroup}
        userSubgroups={userSubgroups} 
        signUpState={signUpState}
        setSignUpState={setSignUpState}
        gameAddress={gameAddress}
        leaderboardData={leaderboardData}
        partyState={partyState}
        partyId={partyId}
        defaultPartyId={defaultPartyId}
        fullLeaderboard={sortedFullLeaderboard}
        doesUserBelong={doesUserBelong}
        joinOpen={joinOpen}
        setJoinOpen={setJoinOpen}
        isGameOver={isSurvivorOver}
      />}
    </div>
  );
};

export default CFBSurvivorContainer;
