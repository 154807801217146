import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { DateTime } from 'luxon';
import './SuperPickemTwoPickCard.scss';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

const SuperPickemTwoPickCard = ({ game, pick, setPick, pickType }) => {

    const picksEntered = 2;
    const isGameOver = true;
    
    const headerDescription = 'College Football Playoff';
    const headerRound = 'First Round';
    const betStyles = ['Spread', 'Total'];
    const dateTime = '2022-01-01T12:00:00Z';
    const betInfo = {
        awayDisplay: '(12) Boise St Broncos',
        awayRecord: '11-2',
        homeDisplay: '(5) Oregon Ducks',
        homeRecord: '12-1',
        awayLogo: "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/logos/ncaa/215.png",
        homeLogo: "https://s3-us-west-2.amazonaws.com/static.fantasydata.com/logos/ncaa/245.png",
        odds: {
            AwayPointSpread: -3,
            HomePointSpread: 3,
            OverUnder: 45,
        },
        awayScore: 14,
        homeScore: 45,
    }

    const isActive = (type, side) => {
        return pickType === type && pick === side;
    }

    const handleBetClick = (type, side) => {
        console.log(`You clicked ${type} ${side}`);
    }

    return (
        <Box
            sx={{
                minWidth: '375px',
                width: '100%', // Let it fill the container
                border: '1px solid black',
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0px',
                marginTop: '20px',
                maxWidth: '550px',
                margin: '0 auto',
            }}
        >

            <Box sx={{ width: '100%', backgroundColor: '#002129;' }}>
                <Typography className="super-pickem-traditional-header-date">
                    {headerDescription} - {headerRound}
                </Typography>
                </Box>

                <Box sx={{ display: 'flex', width: '100%', height: '25px', backgroundColor: '#002129', alignItems: 'center' }}>
                <Box sx={{ textAlign: 'left', marginLeft: '10px', width: '54%' }}>
                    <Typography className="super-pickem-traditional-header-date">
                    {DateTime.fromISO(dateTime).toFormat("ccc MMM d - h:mma 'ET'")}
                    </Typography>
                </Box>
                <Box sx={{ textAlign: 'center', display: 'flex', justifyContent: 'space-between', width: '46%', alignItems: 'flex-end' }}>
                    {betStyles.map((style, index) => (
                    <Typography
                        key={index}
                        className="super-pickem-traditional-header-bet-types"
                        sx={{ width: '23%' }} // Ensures each style takes up 23% of the total width
                    >
                        {style}
                    </Typography>
                    ))}
                </Box>
            </Box>




            {/* Second Row */}
            <Grid container sx={{ width: '100%', height: '92px' }}>
                {/* Teams Section */}
                <Grid 
                    item 
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        width: '54%' 
                    }}
                >
                    {/* Away Team Box */}
                    <Box 
                        sx={{ 
                            height: '36px', 
                            display: 'flex', 
                            alignItems: 'center', 
                            gap: '4px',
                            marginLeft: '4px',
                            width: '100%'
                        }}
                    >
                        <Box 
                            component="img"
                            src={betInfo?.awayLogo}
                            alt="Logo"
                            className="super-pickem-traditional-team-logo"
                        />
                        <Typography
                            className="super-pickem-traditional-team-name"
                            sx={{
                                flexGrow: 1,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                textAlign: 'left'
                            }}
                        >
                            {betInfo?.awayDisplay} {!isGameOver && `(${betInfo?.awayRecord})`}
                        </Typography>
                        {betInfo?.awayScore !== undefined && betInfo?.homeScore !== undefined && isGameOver && (
                            <Typography
                                className="super-pickem-traditional-team-record"
                                sx={{ textAlign: 'right', fontSize: '18px', fontWeight: '700' }}
                            >
                                {betInfo?.awayScore}
                            </Typography>
                        )}
                    </Box>

                    {/* Divider with @ symbol */}
                    <Box 
                        sx={{ 
                            height: '14px', 
                            display: 'flex', 
                            alignItems: 'center',
                            width: '100%'
                        }}
                    >
                        <Typography className="super-pickem-traditional-at-symbol">
                            @
                        </Typography>
                        <Box 
                            sx={{
                                flexGrow: 1,
                                borderTop: '1px solid black'
                            }}
                        />
                    </Box>

                    {/* Home Team Box */}
                    <Box 
                        sx={{ 
                            height: '36px', 
                            display: 'flex', 
                            alignItems: 'center',
                            gap: '4px',
                            marginLeft: '4px',
                            width: '100%'
                        }}
                    >
                        <Box 
                            component="img"
                            src={betInfo?.homeLogo}
                            alt="Logo"
                            className="super-pickem-traditional-team-logo"
                        />
                        <Typography
                            className="super-pickem-traditional-team-name"
                            sx={{
                                flexGrow: 1,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                textAlign: 'left'
                            }}
                        >
                            {betInfo?.homeDisplay} {!isGameOver && `(${betInfo?.homeRecord})`}
                        </Typography>
                        {betInfo?.awayScore !== undefined && betInfo?.homeScore !== undefined && isGameOver && (
                            <Typography
                                className="super-pickem-traditional-team-record"
                                sx={{ textAlign: 'right', fontSize: '18px', fontWeight: '700' }}
                            >
                                {betInfo?.homeScore}
                            </Typography>
                        )}
                    </Box>
                </Grid>

                {/* Scores Section */}
                
                {/* Betting Buttons Section */}
                <Grid 
                    item 
                    sx={{ 
                        display: 'flex', 
                        justifyContent: 'flex-end', 
                        alignItems: 'center',
                        width: '46%'
                    }}
                >
                    <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', paddingRight: '8px' }}>
                        <Grid item xs={6}>
                            <Box className="super-pickem-traditional-button-pair">
                                <Button 
                                    className={`super-pickem-traditional-bet-button ${isActive("Spread", "Away") ? 'active' : ''}`}
                                    onClick={() => betInfo?.odds?.AwayPointSpread !== undefined && handleBetClick("Spread", "Away")}
                                    disabled={betInfo?.odds?.AwayPointSpread === undefined}
                                >
                                    <span className={`super-pickem-traditional-bet-button-text1 ${isActive("Spread", "Away") ? 'active' : ''}`}>
                                        {betInfo?.odds?.AwayPointSpread !== undefined 
                                            ? (betInfo?.odds?.AwayPointSpread > 0 ? '+' + betInfo?.odds?.AwayPointSpread : betInfo?.odds?.AwayPointSpread === 0 ? 'Pk' : betInfo?.odds?.AwayPointSpread)
                                            : <LockIcon sx={{ color: '#002129', fontSize: '1.3rem' }} />
                                        }
                                    </span>
                                </Button>
                                <Button 
                                    className={`super-pickem-traditional-bet-button ${isActive("Spread", "Home") ? 'active' : ''}`}
                                    onClick={() => betInfo?.odds?.HomePointSpread !== undefined && handleBetClick("Spread", "Home")}
                                    disabled={betInfo?.odds?.HomePointSpread === undefined}
                                >
                                    <span className={`super-pickem-traditional-bet-button-text1 ${isActive("Spread", "Home") ? 'active' : ''}`}>
                                        {betInfo?.odds?.HomePointSpread !== undefined 
                                            ? (betInfo?.odds?.HomePointSpread > 0 ? '+' + betInfo?.odds?.HomePointSpread : betInfo?.odds?.HomePointSpread === 0 ? 'Pk' : betInfo?.odds?.HomePointSpread)
                                            : <LockIcon sx={{ color: '#002129', fontSize: '1.3rem' }} />
                                        }
                                    </span>
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box className="super-pickem-traditional-button-pair">
                                <Button 
                                    className={`super-pickem-traditional-bet-button ${isActive("Total", "Over") ? 'active' : ''}`}
                                    onClick={() => betInfo?.odds?.OverUnder !== undefined && handleBetClick("Total", "Over")}
                                    disabled={betInfo?.odds?.OverUnder === undefined}
                                >
                                    <span className={`super-pickem-traditional-bet-button-text1 ${isActive("Total", "Over") ? 'active' : ''}`}>
                                        {betInfo?.odds?.OverUnder !== undefined 
                                            ? `O ${betInfo?.odds?.OverUnder}` 
                                            : <LockIcon sx={{ color: '#002129', fontSize: '1.3rem' }} />
                                        }
                                    </span>
                                </Button>
                                <Button 
                                    className={`super-pickem-traditional-bet-button ${isActive("Total", "Under") ? 'active' : ''}`}
                                    onClick={() => betInfo?.odds?.OverUnder !== undefined && handleBetClick("Total", "Under")}
                                    disabled={betInfo?.odds?.OverUnder === undefined}
                                >
                                    <span className={`super-pickem-traditional-bet-button-text1 ${isActive("Total", "Under") ? 'active' : ''}`}>
                                        {betInfo?.odds?.OverUnder !== undefined 
                                            ? `U ${betInfo?.odds?.OverUnder}` 
                                            : <LockIcon sx={{ color: '#002129', fontSize: '1.3rem' }} />
                                        }
                                    </span>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Box sx={{ width: '100%', backgroundColor: '#002129', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography
                    className="super-pickem-traditional-header-date"
                    sx={{ textAlign: 'left', marginLeft: '10px' }}
                >
                    Eugene, OR
                </Typography>
                <Typography
                    className="super-pickem-traditional-header-date"
                    sx={{ textAlign: 'right', marginRight: '30px' }}
                >
                    ESPN
                </Typography>
            </Box>

            <Box sx={{ width: '100%', backgroundColor: '#fff', alignItems: 'center' }}>
                <Typography
                    className="super-pickem-traditional-header-date"
                    sx={{ textAlign: 'center', color: picksEntered !== 2 ? '#CC293C' : '#00AA72' }}
                >
                    Game Picks: {picksEntered}/2 {picksEntered === 2 ? <CheckIcon sx={{ color: '#00AA72' }} /> : <CloseIcon sx={{ color: '#CC293C' }} />}
                </Typography>
            </Box>

            
        </Box>
    )
}

export default SuperPickemTwoPickCard;