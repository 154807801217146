import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material'; 
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'; 
import './SponsorTab.scss';

const SponsorLocations = ({ location, address1, address2, phoneNumber, hours, extraInfo, locationCount }) => {
    const [locationOpen, setLocationOpen] = useState(locationCount === 1);

    // Prevent toggling if locationCount is 1
    const toggleLocation = () => {
        if (locationCount > 1) {
            setLocationOpen(prevState => !prevState);
        }
    };

    return (
        <Box className="sponsor-tab-locations-container">
            <Box className="sponsor-tab-header" onClick={toggleLocation}>
                <Typography className='sponsor-tab-location-text' sx={{ cursor: locationCount === 1 ? 'default' : 'pointer'}}>
                    {location}
                </Typography>
                {locationCount > 1 && ( // Only show the icon if there is more than one location
                    <KeyboardArrowDownIcon 
                        className={`sponsor-tab-icon ${locationOpen ? 'open' : ''}`}
                    />
                )}
            </Box>

            {locationOpen && (
                <>
                    <Typography className='sponsor-tab-main-text-first'>
                        {address1}
                    </Typography>
                    <Typography className='sponsor-tab-main-text'>
                        {address2}
                    </Typography>
                    <Typography className='sponsor-tab-main-text'>
                        {phoneNumber}
                    </Typography>

                    {/* Hours Section */}
                    <Box className="sponsor-tab-hours">
                        {hours.map((time, index) => (
                            <Box key={index} className="sponsor-tab-hours-block">
                                <Typography className='sponsor-tab-main-text'>
                                    {time?.days}
                                </Typography>
                                <Typography className='sponsor-tab-main-text'>
                                    {time?.hours}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                    <Typography className='sponsor-tab-main-text'>
                        {extraInfo}
                    </Typography>
                </>
            )}
        </Box>
    );
};

export default SponsorLocations;
