import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AppBar, Box, Button, Toolbar } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import navbarLogo from "../../assets/images/navbar-logo.svg";
import { useDispatch } from "react-redux";
import { userLogoutAction } from "../../redux/actions/loginImport";
import { getUserDataFromLocalStorage } from "../../redux/actions/loginImport";
import { useGetUserData } from "../BirdiePool/hooks/index";
import { useGetPrivatePools } from "./../PrivatePools/hooks/useGetPrivatePools"
import { useGetPublicPools } from "./../PublicPools/hooks/useGetPublicPools"
import { fetchGames } from '../../redux/actions/gameImport';
import UserDropDown from "../cornerDropDown/dropDownMenu";
import { NavLink } from "react-router-dom";
import {
  ListItem,
  Drawer,
  List,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";
// import './styles.scss';
import ThemeProvider from "../../contexts/ThemeProvider";
import MyPools from "./MyPools";
import BirdiePoolsDropdown from "./BirdiePoolsDropdown";
import { useNavigate } from "react-router-dom";
import useNavigateToTop from "../../hooks/general/useNavigateToTop";



const activeStyle = {
  textDecoration: "none",
  fontWeight: "bold",
  background: "transparent",
  color: "#FFC60A", // Change as needed for active link
};

const CustomIconButton = styled(IconButton)({
  color: "var(--color-white)",
  "&:hover, &:active": {
    color: "var(--color-yellow)",
  },
});



const NavLinkItem = (props) => {
  const { label, path } = props;
  const navigate = useNavigate();

  const handleLinkClick = (e) => {
    e.preventDefault(); // Prevent the default anchor behavior
    window.scrollTo(0, 0); // Scroll to the top of the page
    navigate(path); // Navigate to the specified path
  };

  return (
    <NavLink
      style={({ isActive }) =>
        isActive && path
          ? activeStyle
          : {
              color: "var(--color-white)",
              textDecoration: "none",
              height: 38,
              alignItems: "center",
              display: "flex",
              background: "transparent",
            }
      }
      variant="text"
      to={path}
      onClick={handleLinkClick} // Attach the custom click handler
    >
      {label}
    </NavLink>
  );
};

const MobileNavItem = (props) => {
  const { handleClick, label, path } = props;

  const handleItemClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
    // Call the original handleClick function if provided
    if (handleClick) {
      handleClick();
    }
  };

  return (
    <ListItem
      sx={{ width: '100%', "&:hover, &:active": { color: "var(--color-yellow)" } }}
      onClick={handleItemClick}
    >
      <NavLinkItem label={label} path={path} />
    </ListItem>
  );
};

const Header = () => {
  const dispatch = useDispatch();
  const userDataId = getUserDataFromLocalStorage()?.user_id;
  const navigateToTop = useNavigateToTop();
  const handleLogin = () => {
    if (!userDataId) {
    navigateToTop('/login');
    } else {
      navigateToTop('/my-pools');
    } 
  }
  const { mutate: fetchUserData, data: userData, isLoading: userLoading, isError: userError, error: userErrorData } = useGetUserData();
  //console.log("USER DATA FROM HOOK", userData?.Games);
  const { data, error, isLoading, refetch } = useGetPrivatePools();
  const { data: publicPoolsData, publicIsLoading, publicIsError, publicError } = useGetPublicPools();
  //console.log("USER DATA PRIVATE GAMES", data)
  const [gamesData, setGamesData] =useState([]);
  let matchingGames = [];
  useEffect(() => {
    if (userData && data && publicPoolsData) {
      const userGameIds = userData.Games?.map(game => game.gameID) || [];
       // Combine both private and public pools data
       const allPools = [...data, ...publicPoolsData];
      matchingGames = allPools.filter(pool => userGameIds?.includes(pool.gameID)) || publicPoolsData;
      // const matchingSuperPickEm = userData.Games?.filter(game => game.gameName === "NBA Playoffs");
      //console.log("Match Super Pick Em:", matchingSuperPickEm);
      // if (matchingSuperPickEm.length > 0) {
      //   matchingGames = [...matchingGames, ...matchingSuperPickEm];
      // }
      matchingGames = matchingGames.reverse();
      setGamesData(matchingGames);
      //console.log("USER DATA Matching Games:", matchingGames);
    }
  }, [userData, data, publicPoolsData]);
  //console.log("USER DATA MATCHING OUTSIDE EFFECT", gamesData)
  const logout = () => {
    dispatch(userLogoutAction());
  };

  const [isDropDownOpen, setisDropDownOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  //console.log("userData", userData);

  const toggleDropDown = (e) => {
    setisDropDownOpen(!isDropDownOpen);
    e.stopPropagation();
  };

  useEffect(() => {
    if (userDataId) {
        fetchUserData(userDataId);
    }
}, [fetchUserData, userDataId]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth > 768) {
        setIsDrawerOpen(false); // Close the drawer when resizing to desktop
      }
      if (window.innerWidth < 768) {
        setisDropDownOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };


  return (
    <ThemeProvider>
      <header>
        <AppBar
          component="nav"
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            height: isDrawerOpen && windowWidth >= 768 ? "auto" : "80px",
          }}
        >
          <Toolbar
            style={{
              alignItems: "center",
              padding: windowWidth >= 768 ? "20px 50px" : "10px 20px",
            }}
          >
            <Link to="/">
              <img src={navbarLogo} alt="" />
            </Link>
            {windowWidth >= 768 && (
              <Box
                style={{
                  marginLeft: "auto",
                  width: "100%",
                  alignItems: "center",
                  maxWidth: "650px",
                  display: "flex",
                  color: "var(--color-white)",
                  justifyContent: "right",
                  gap: 24,
                }}
              >
                {gamesData && gamesData.length > 0 && <MyPools games={gamesData} />}
                <div style={{ whiteSpace: 'nowrap', marginRight: '-5px' }}>
                  <NavLinkItem label="Pool Hall" path="/pool-hall" />
                </div>
                <div style={{ whiteSpace: 'nowrap', marginRight: '5px' }}>
                <BirdiePoolsDropdown />
                </div>
                <div style={{ whiteSpace: 'nowrap' }}>
                  <NavLinkItem label="Our Story" path="/story" />
                </div>
                <div style={{ whiteSpace: 'nowrap', marginRight: '5px'}}>
                  <NavLinkItem label="Contact Us" path="/contact" />
                </div>
                {!userDataId ? (
                  <Link to="/login" style={{ whiteSpace: 'nowrap' }}>
                  <Button
                    sx={{
                      backgroundColor: "var(--color-yellow)",
                      color: "var(--primary-color)",
                      "&:hover": {
                        opacity: 0.95,
                        backgroundColor: "var(--color-yellow)",
                        color: "var(--primary-color)",
                      },
                    }}
                    variant="text"
                  >
                    Login / Sign Up
                  </Button>
                </Link>
                ) : (
                  <>
                    {userData?.Settings?.Profile?.profileImage ? (
                      <div
                        onClick={(e) => toggleDropDown(e)}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          cursor: "pointer",
                          backgroundImage: `URL(${userData?.Settings?.Profile?.profileImage})`,
                        }}
                      />
                    ) : (
                      <Box
                        sx={{
                          backgroundColor: "var(--color-grey)",
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          paddingTop: "3px",
                          boxSizing: "border-box",
                        }}
                      >
                        <PersonIcon
                          onClick={(e) => toggleDropDown(e)}
                          sx={{
                            fontSize: "1.8rem",
                            color: "var(--color-white)",
                          }}
                        />
                      </Box>
                    )}
                  </>
                )}
              </Box>
            )}
            {windowWidth < 768 && (
              <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                  <Button
                    onClick={handleLogin} // Replace with your login handler function
                    sx={{
                      display: 'flex',
                      height: '40px',
                      padding: '6px 16px',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: 'var(--borderRadius, 4px)',
                      background: '#FFC60A',
                      color: '#000',
                      fontWeight: 600,
                      marginRight: '16px',
                      '&:hover': {
                        background: '#FFC60A', // Maintain the same background color on hover
                        boxShadow: 'none', // Optionally remove box shadow on hover
                      },
                      '&:focus': {
                        background: '#FFC60A', // Maintain the same background color on focus
                        boxShadow: 'none', // Optionally remove box shadow on focus
                      },
                      '&:active': {
                        background: '#FFC60A', // Maintain the same background color on active
                        boxShadow: 'none', // Optionally remove box shadow on active
                      },
                    }}
                  >
                    {!userDataId ? 'Login' : 'My Pools'}
                  </Button>
                <CustomIconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  onClick={toggleDrawer} // Ensure this is placed on the CustomIconButton itself
                >
                  <MenuIcon style={{ fontSize: "2.5rem" }} />
                </CustomIconButton>
              </Box>
            )}
            {windowWidth > 768 && (
              <UserDropDown
                isDropDownOpen={isDropDownOpen}
                toggleDropDown={toggleDropDown}
              />
            )}
            <Drawer
              variant="temporary"
              anchor="top"
              open={windowWidth <= 768 && isDrawerOpen}
              onClick={handleCloseDrawer}
              sx={{
                width: "100%",
                flexShrink: 0,
                zIndex: 1099,
              }}
            >
              <div style={{ height: "64px" }}></div>
              <List
                sx={{
                  display: "inline-grid",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flexStart",
                  paddingTop: "25px",
                  paddingLeft: "25px",
                  paddingRight: "25px",
                  // height: "100%", // Adjust height as needed
                  backgroundColor: "var(--primary-color)",
                  "& a": {
                    color: "white", // Default color for links
                    textDecoration: "none", // Remove underline from links
                  },
                  "& a:hover, & a:active": {
                    color: "var(--color-yellow)", // Color for hover and active states
                  },
                }}
              >
                <MyPools games={gamesData} />
                <MobileNavItem
                  path="/pool-hall"
                  label="Pool Hall"
                  handleClick={handleCloseDrawer}
                />
                {/* Uncomment this when we have the private pools page */}
                <MobileNavItem
                  path="/private-pools"
                  label="Birdie Pools"
                  handleClick={handleCloseDrawer}
                />
                <MobileNavItem
                  path="/birdie-pool-demo"
                  label="Birdie Pool Demo"
                  handleClick={handleCloseDrawer}
                />
                <MobileNavItem
                  path="/story"
                  label="Our Story"
                  handleClick={handleCloseDrawer}
                />
                <MobileNavItem
                  path="/contact"
                  label="Contact Us"
                  handleClick={handleCloseDrawer}
                />
                {userDataId && <MobileNavItem
                  path="/profile/general"
                  label="My Profile"
                  handleClick={handleCloseDrawer}
                />}
                {userDataId? (
                  <MobileNavItem
                    path={null}
                    label="Sign out"
                    handleClick={(e) => {
                      handleCloseDrawer();
                      logout();
                    }}
                  />
                ) : (
                  <MobileNavItem
                    path="/login"
                    label="Login / Sign Up"
                    handleClick={handleCloseDrawer}
                  />
                )}
              </List>
            </Drawer>
          </Toolbar>
        </AppBar>
        <UserDropDown
          isDropDownOpen={isDropDownOpen}
          toggleDropDown={toggleDropDown}
        />
      </header>
    </ThemeProvider>
  );
};

export default Header;
