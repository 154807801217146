import React from 'react';
import { Box, Typography } from '@mui/material'; 
import SponsorLocations from './SponsorLocations';
import './SponsorTab.scss';

const SponsorStoryPage = ({ sponsorInfo }) => {
    const { storyTopText, locations, catering, specials, storyBottomText } = sponsorInfo || {};
    //console.log("Catering: ", catering);

    return (
        <Box className="sponsor-tab-container">
            {/* Render each item in storyTopText as a separate paragraph */}
            {storyTopText?.map((text, index) => (
                <Typography key={index} className="sponsor-tab-main-text">
                    {text}
                </Typography>
            ))}

            <Typography className="sponsor-tab-header-text">
                Location{locations?.length > 1 ? 's' : ''}
            </Typography>
            
            {locations?.map((info, index) => (
                <SponsorLocations 
                    key={index}
                    location={info?.location}
                    address1={info?.address1}
                    address2={info?.address2}
                    phoneNumber={info?.phoneNumber}
                    hours={info?.hours}
                    extraInfo={info?.extraInfo}
                    locationCount={locations?.length}
                />
            ))}

            {catering && (
                <>
                    <Typography className="sponsor-tab-header-text">
                        Catering
                    </Typography>
                    {catering?.map((item, index) => (
                        <Box key={index}>
                            {/* Combine message and link into a single element */}
                            <a
                                href={item?.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="sponsor-tab-catering-text"
                            >
                                {item?.message}
                            </a>
                        </Box>
                    ))}
                </>
            )}
            {specials && (
                <Box>
                    <Typography className="sponsor-tab-header-text">
                        Specials
                    </Typography>

                    {specials?.map((special, index) => (
                        <Box key={index} className="sponsor-tab-specials-container">
                            {/* Display header */}
                            <Typography className="sponsor-tab-main-text-underline">
                                {special?.header}
                            </Typography>

                            {/* Display body lines */}
                            {special?.body?.map((line, lineIndex) => (
                                <Box
                                    key={lineIndex}
                                    className={`sponsor-tab-body-line ${line?.image ? 'with-image' : 'no-image'}`}
                                >
                                    {line?.image && (
                                        <img
                                            src={line.image}
                                            alt=""
                                            className="sponsor-tab-body-image"
                                        />
                                    )}
                                    <Typography className="sponsor-tab-main-text">
                                        {line?.message}
                                    </Typography>
                                    {line?.image && (
                                        <img
                                            src={line.image}
                                            alt=""
                                            className="sponsor-tab-body-image"
                                        />
                                    )}
                                </Box>
                            ))}
                        </Box>
                    ))}
                </Box>
            )}

            {storyBottomText?.map((text, index) => (
                <Typography key={index} className="sponsor-tab-bottom-text">
                    {text}
                </Typography>
            ))}

        </Box>
    );
}

export default SponsorStoryPage;
