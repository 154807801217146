import React from "react";
import { Box } from "@mui/material";

import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import ThemeProvider from "../contexts/ThemeProvider";

export default function MainLayout({ children }) {
  return (
    <ThemeProvider>
      <Header />
      <Box
        sx={{
          minHeight: "calc(var(--main-content-min-height))",
          width: "100%",
          margin: "0 auto",
          marginTop: "var(--navbar-height)",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className="overflow-y-auto max-w-full w-full flex flex-col flex-1">
          {children}
        </div>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}
