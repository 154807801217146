import React from "react";
import { Button, Modal, Typography, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ErrorModal = ({ internalErrorOpen, setInternalErrorOpen, internalErrorMessage, setInternalErrorMessage }) => {

    return (
        <Modal
            open={internalErrorOpen}
            onClose={() => { setInternalErrorMessage(''); setInternalErrorOpen(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{
            // Prevent closing when clicking outside
            onClick: (e) => e.stopPropagation(),
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    borderRadius: '8px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '328px',
                    height: 'auto',
                    bgcolor: '#FFF',
                    padding: '16px',
                    outline: 'none', // Remove default focus behavior
                }}
            >
                <Typography 
                    id="modal-modal-title" 
                    variant="h6" 
                    component="h2"
                    sx={{ 
                        textAlign: 'center',
                        color: '#000000',
                        fontSize: '16px',
                        fontWeight: '800',
                        paddingBottom: '20px',
                    }}    
                >
                    Error Submitting Pick
                </Typography>
                <Typography 
                    id="modal-modal-description" 
                    sx={{ 
                        textAlign: 'center',
                        color: '#000000',
                        fontSize: '16px',
                        fontWeight: '800',
                        whiteSpace: 'nowrap',
                    }}>
                    {internalErrorMessage}
                </Typography>
                <Typography 
                    id="modal-modal-description" 
                    sx={{ 
                        textAlign: 'center',
                        color: '#000000',
                        fontSize: '16px',
                        fontWeight: '400',
                        marginBottom: '20px'
                    }}>
                    Please try again later.
                </Typography>
                <Typography 
                    id="modal-modal-description" 
                    sx={{ 
                        textAlign: 'center',
                        color: '#000000',
                        fontSize: '16px',
                        fontWeight: '400',
                    }}>
                    If the problem persists, please contact support.
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%', // Ensures the Box takes the full width of its container
                    }}
                    >
                    <CloseIcon
                        sx={{
                        color: '#CC293C',
                        fontSize: '32px', // Sets the size of the icon
                        }}
                    />
                </Box>
                <Button 
                    variant='contained'
                    onClick={() => {
                        setInternalErrorOpen(false);
                        setInternalErrorMessage('');
                        window.location.reload(); // This will reload the page
                    }}
                    sx={{
                        width: '115px',
                        height: '38px',
                        marginTop: '20px', // Add top margin
                        display: 'block',
                        marginLeft: 'auto', // Center horizontally within a flex container
                        marginRight: 'auto', // Center horizontally within a flex container
                    }}
                >
                    OK
                </Button>
            </Box>
        </Modal>
    );
}

export default ErrorModal;