import React, { useEffect, useState } from "react";
import { useAdminDashContext } from "../context/AdminDashContext";
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";
import { useLocation } from "react-router-dom"; // Import useLocation
import AddQuickPickGames from "./AddQuickPickGames";
import AddQuickPickProps from "./AddQuickPickProps";
import AddQuickPickResults from "./AddQuickPickResults";
import GetPropIds from "./GetPropIds";

const QuickPickHome = () => {
    const {
        pageState,
        setPageState,
        leagueState,
        NFLCurrentWeek,
        handleBackClick
    } = useAdminDashContext();

    const navigateToTop = useNavigateToTop();
    const location = useLocation(); // Access query parameters

    // State to manage the selected quick pick tab
    const [quickPickState, setQuickPickState] = useState("Add Games");

    // State for season and week
    const seasons = ['2024'];
    const [selectedSeason, setSelectedSeason] = useState(seasons[0]); // Default to the first season
    const [selectedWeek, setSelectedWeek] = useState(NFLCurrentWeek || 1); // Default to NFLCurrentWeek or week 1
    
    const handleSeasonChange = (event) => {
        setSelectedSeason(event.target.value); // Update the selected season
    };

    const handleWeekChange = (event) => {
        setSelectedWeek(Number(event.target.value)); // Update the selected week
    };

    // Create an array of weeks from 1 to 18
    const weeks = Array.from({ length: 18 }, (_, index) => index + 1);

    // Extract selectedTab from URL when the component mounts
    useEffect(() => {
        console.log("QuickPickHome Mounted");
        const queryParams = new URLSearchParams(location.search);
        const selectedTab = queryParams.get("selectedTab"); // Get the selected tab from the URL
        console.log("Selected Tab", selectedTab);

        // Set quickPickState to selectedTab if it's valid; otherwise, set to default "Add Games"
        if (selectedTab && ["Add Games", "Add Props", "Prop Results", "Get Prop IDs"].includes(selectedTab)) {
            setQuickPickState(selectedTab);
        } else {
            // If selectedTab is null or invalid, set default and update URL
            setQuickPickState("Add Games"); // Default to "Add Games"
            // Only update URL if it's not already set
            console.log("Setting the NEW URL");
            navigateToTop(`/admin-dash-test/QuickPick?league=${leagueState}&selectedTab=Add%20Games`);
        }
    }, [location.search, leagueState]); // Depend on location.search to update if it changes

    // Handle button clicks
    const handleButtonClick = (buttonName) => {
        setQuickPickState(buttonName);
        // Update the URL with selectedTab query parameter
        navigateToTop(`/admin-dash-test/QuickPick?league=${leagueState}&selectedTab=${buttonName}`);
    };

    // Define button names
    const buttonNames = ["Add Games", "Add Props", "Prop Results", "Get Prop IDs"];

    return (
        <div>
            {/* QuickPick Home Header */}
            <h1 style={{ fontSize: '36px', fontWeight: 700 }}>
                QuickPick Home
            </h1>

            {/* Back to Admin Home Link */}
            <p 
                style={{ 
                    color: 'blue', 
                    textDecoration: 'underline', 
                    cursor: 'pointer' 
                }} 
                onClick={handleBackClick}
            >
                Back to Admin Home
            </p>

            {/* Row of Buttons */}
            <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
                {buttonNames?.map((name) => (
                    <button 
                        key={name} 
                        onClick={() => handleButtonClick(name)} 
                        style={{ 
                            padding: '10px 10px', 
                            border: '2px solid #002129',
                            borderRadius: '5px',
                            backgroundColor: quickPickState === name ? '#C0C0C0' : 'white',
                            color: '#002129',
                            cursor: 'pointer'
                        }}
                    >
                        {name}
                    </button>
                ))}
            </div>

            {/* Dropdown Menu for Seasons */}
            <div style={{ marginBottom: '20px', marginTop: '10px' }}>
                <label htmlFor="season-select" style={{ marginRight: '10px' }}>
                    Season:
                </label>
                <select 
                    id="season-select" 
                    value={selectedSeason} 
                    onChange={handleSeasonChange}
                    style={{ padding: '5px', borderRadius: '4px' }}
                >
                    {seasons.map((season) => (
                        <option key={season} value={season}>
                            {season}
                        </option>
                    ))}
                </select>
            </div>

            {/* Dropdown Menu for Weeks */}
            <div style={{ marginBottom: '20px' }}>
                <label htmlFor="week-select" style={{ marginRight: '10px' }}>
                    Week:
                </label>
                <select 
                    id="week-select" 
                    value={selectedWeek} 
                    onChange={handleWeekChange}
                    style={{ padding: '5px', borderRadius: '4px' }}
                >
                    {weeks.map((week) => (
                        <option key={week} value={week}>
                            {week}
                        </option>
                    ))}
                </select>
            </div>

            {/* Render selected component based on quickPickState */}
            {quickPickState === "Add Games" && (
                <AddQuickPickGames 
                    selectedSeason={selectedSeason}
                    selectedWeek={selectedWeek}
                />
            )}
            
            {quickPickState === "Add Props" && (
                <AddQuickPickProps
                    selectedSeason={selectedSeason}
                    selectedWeek={selectedWeek}
                />
            )}

            {quickPickState === "Prop Results" && (
                <AddQuickPickResults
                    selectedSeason={selectedSeason}
                    selectedWeek={selectedWeek} 
                />
            )}

            {quickPickState === "Get Prop IDs" && (
                <GetPropIds 
                    selectedSeason={selectedSeason}
                    selectedWeek={selectedWeek}
                />
            )}

        </div>
    );
}

export default QuickPickHome;
