import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme, Typography, Select, MenuItem, FormControl } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import useNavigateToTop from '../../hooks/general/useNavigateToTop';

const PartyDropdown = (props) => {
    const { 
        userSubgroups, 
        gameAddress, 
        partyState, 
        setPartyState, 
        clickState, 
        setPartyId 
    } = props;

    const userGroups = userSubgroups?.map(subgroup => subgroup.name);
    //console.log("USER GROUPS", userGroups);

    const navigateToTop = useNavigateToTop();
    const { gameId, component, pageState } = useParams();
    //console.log("Optional State in MyPartyDropdown", pageState);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const uriEncoded = (link) => encodeURI(`${encodeURIComponent(link)}`);

    const groupNames = clickState !== "MY_PARTY" ? ["Pool Party", ...userGroups] : [...userGroups];

    useEffect(() => {
        // Set default state to "Pool Party" if no state is present
        if (!partyState) {
            setPartyState("Pool Party");
        }
    }, [partyState, setPartyState]);

    const handleChange = (e) => {
        //console.log("EVENT TARGET VALUE", e.target.value);
        setPartyState(e.target.value);

        let newPartyId;
        if (e.target.value === "Pool Party") {
            //console.log("POOL PARTY is EVENT TARGET VALUE");
            newPartyId = gameId;
        } else {
            const subgroupSelection = userSubgroups?.find(subgroup => subgroup.name === e.target.value);
            //console.log("SUBGROUP in Dropdown in Container", subgroupSelection);
            newPartyId = subgroupSelection?.subGroupID;
            //console.log("NEW PARTY ID in Dropdown in Container", newPartyId);
        }

        setPartyId(newPartyId);

        //console.log("NEW STATE", e.target.value);
        navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded(component)}/${uriEncoded(newPartyId)}`);
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: "#FFC60A", height: isMobile ? '39px' : '50px', borderBottom: "2px solid #000", margin: '0 auto' }}>
            <Typography 
                sx={{ 
                    fontSize: isMobile ? '20px' : '26px', 
                    fontWeight: '700', 
                    marginRight: '10px' 
                }}
            >
                Party:
            </Typography>
            <FormControl
                variant="outlined"
                size="small"
                sx={{ 
                    width: isMobile ? '162px' : '200px' 
                }}
            >
                <Select
                    value={partyState}
                    onChange={handleChange}
                    style={{
                      textAlign: "center",
                      fontSize: isMobile ? '12px' : '14px',
                      padding: isMobile ? '5px' : '10px',
                      height: isMobile ? '24px' : '36px',
                      border: "1px solid #000000",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "4px",
                      boxShadow: "none", // Remove default shadow if you want a flat look
                      width: '100%'
                  }}
                >
                    {groupNames.map(groupName => (
                        <MenuItem key={groupName} value={groupName}>
                            {isMobile && groupName.length > 30 ? `${groupName.slice(0, 30)}...` : groupName}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default PartyDropdown;
